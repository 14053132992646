.form-content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    gap: 16px;
}

.form-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
    min-width: calc(50% - 8px);
}

.form-box{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 18px 25px;
    border-radius: 20px;
    box-shadow: var(--shadow-elevation-high);
}

.form-contrcts .form-box p{
  text-align: justify;
}

.remove-cross{
  border-radius: 5px;
  font-weight: 600;
  background: var(--error-field);
  box-shadow: 0 4px 14px 0 rgb(255 77 79 / 38%);
}

.alias-trash{
  height: 40px;
  margin-bottom: 8px;
}

.form-box .relative-input{
  width: 100%;
} 

.form-box .relative-input label span, .form-box-select label span {
  color: #ff5f2e;
} 

.form-box .relative-input .ant-select-selector, .form-box .relative-input .ant-picker {
  margin-bottom: 0;
} 

.form-box .ant-picker{
  width: 100%;

}

.form-box .form-box-input{
  width: 100%;
}

.form-box .form-box-select{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-box .form-box-textarea{
  width: 100%;
  border: 1px solid #DADCE0 !important;
  color: #898686;
  max-height: 190px;
  height: 190px;
  min-height: 190px;
}

.form-box .ant-checkbox-wrapper+.ant-checkbox-wrapper{
  margin-left: 0;
}

.cross-contract{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}

.cross-contract .cross-contract-column{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: calc(50% - 4px);
}

.form-box .input-row-group{
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #e4e4e4;
  padding: 20px 12px;
  width: 100%;
  border-radius: 12px;
}
.form-box .add-escalation{
  margin: 20px 0 5px;
  border-radius: 5px;
  box-shadow: 0 4px 14px 0 #43a6e55e;
  font-weight: 600;
  background: #43a6e5;
  border: none;
}

.form-box .input-row-group .input-row-subgroup{
  background-color: #ededed;
  border: none;
  margin-top: 20px;
}

.form-box .input-row-group .input-row{
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-end;
  justify-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}

.form-box .input-row-group .input-row .relative-input{
  width: calc(14.28% - 8px);
}

.form-box .input-row-group .input-row .relative-input input{
  margin: 0;
}

@media (max-width: 1359px) {
  .form-box .input-row-group .input-row .relative-input{
    width: calc(20% - 8px);
  }
}

@media (max-width: 1120px) {
  .form-box .input-row-group .input-row .relative-input{
    width: calc(33% - 8px);
  }
}

@media (max-width: 821px) {
  .form-box .input-row-group .input-row .relative-input{
    width: 100%
  }
}

.form-box .input-row-group .input-row button{
  height: 40px;
}

.form-box .input-row-group .input-row button.remove{
  width: 40px;
}

.form-box .input-row-group .input-row button.secondary{
  background-color: #8a7dbe;
  border-color: #8a7dbe;
}

.form-box button.add-item{
  height: 40px;
  margin-top: 8px;
  margin-left: 0;
}

.form-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 16px;
  gap: 8px;
}

.form-header button.general-btn.save{
  background: linear-gradient(135deg, #25afcd, #197d93, #1e90a9);
  box-shadow: 0 6px 20px 0 rgba(29, 144, 169, 0.45);
}

.form-header button.general-btn.warning{
  background: linear-gradient(135deg, #d0df2b, #abab25, #bcc221);
  box-shadow: 0 6px 20px 0 rgba(29, 144, 169, 0.45);
}
.tabs-contracts-upd .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: var(--outrageouse-orange);
  text-shadow: 0 0 .25px var(--outrageouse-orange);
}
.tabs-contracts-upd .ant-tabs-tab:hover, .tabs-contracts-upd .ant-tabs-tab:active, .tabs-contracts-upd .ant-tabs-tab:focus{
  color: #ff5f2ebd;
}
.tabs-contracts-upd .ant-tabs-ink-bar{
  background-color: var(--outrageouse-orange);
}
.load-overview {
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 1000;
  position: absolute;
  font-size: 15px;
  font-weight: 700;
  padding-top: 85px;
  text-align: center;
  background: #d7d7d742;
  border-radius: 2px;
  backdrop-filter: blur(1.5px);
}
.load-overview .spinr {
  top: 68px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
}
.load-overview .spinr .ant-spin-dot-item {
  background-color: var(--outrageouse-orange);
}
/********* medias *********/
@media (max-width: 835px) {
  .container-admin.contrcts{
    margin-bottom: 50px;
  }
  .form-content{
    flex-direction: column;
  }
  .form-column{
    min-width: 100%;
  }
  .form-contrcts .general-btn, .form-box button.add-item{
    width: 100%;
    max-width: 100%;
    min-width: unset; 
  }
}