.create-box{
    text-align: left;
    position: relative;
    border-radius: 20px;
    background-color: #FFF;
    padding:  40px 30px 31px 30px;
    box-shadow: var(--shadow-elevation-high);
}
.create-box h1{
    padding-top: 17px;
    font-size: 25px;
    margin-bottom: 0px;
}
.create-box .forms input , .create-box .forms .ant-input-affix-wrapper, .create-box .forms .ant-select-selector, .create-box .ant-picker, .modal-box .forms input, .modal-box .forms .ant-select-selector{
    background-color: #fff;
}
.forms .avatar-uploader{
    width:  auto;
    padding-bottom: 9px;
}
.forms .avatar-uploader.avatar-profile{
    padding-bottom: 14px;
}
.forms .avatar-uploader .ant-upload.ant-upload-select-picture-card{
    width: 115px;
    height: 115px;
    border-radius: 5px;
}
.forms .avatar-uploader .ant-upload.ant-upload-select-picture-card .anticon{
    font-size: 25px;
    color: #8c8c8c;
}
.forms .avatar-uploader.imageErr .ant-upload.ant-upload-select-picture-card{
    border: 2px dashed #FD6A88;
}
.cover-desc{
    width: 170px;
    color: #8c8c8c;
    font-size: 11px;
    vertical-align: top;
    display: inline-block;
}
.allWidth{
    display: block;
}
.selector-general.labels-na .ant-select-selector{
    padding-top: 5px;
}
.spinner-release{
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.spinner-release .ant-spin-dot-item{
    background-color: var(--outrageouse-orange);
}
/********* Information - Adv.Options *********/
.create-box.additional{
    margin-top: 40px;
    margin-right: 0px;
}
.create-box.additional .forms{
    display: grid;
}
.advance-info{
    float: left;
    margin: auto;
    display: grid;
    min-width: 705px;
    margin-top: 32px;
    margin-left: -30px;
    text-align: center;
}
.advanced-btn{ 
    border: 1px solid #ebebeb;
    border-radius: 5px;
    color: #8c8c8c;
}
.advanced-btn:hover, .advanced-btn:focus, .advanced-btn:active{
    border: 1px solid #ebebeb80;
    color: #b0b0b0;
}
.additional .ant-upload.ant-upload-select-picture-card{
    float: left;
}
/********* Participants  *********/
.space-card{
    padding-bottom: 15px;
}
.title-add-flex{
    display: flex;
    justify-content: space-between;
}
.expnd{
    margin-bottom: 24px;
}
.drop-people .ant-dropdown-menu{
    width: 210px;
    padding: 5px 0;
    box-shadow: 0 3px 1px -4px rgb(0, 0, 0, 12%), 2px 6px 14px 0 rgb(0 ,0, 0, 8%), 0 9px 28px 8px rgb(0, 0 ,0, 5%);
}
.drop-people .ant-dropdown-menu .ant-dropdown-menu-title-content{
    font-size: 11px;
}
.drop-people .ant-dropdown-menu li:nth-child(1) .ant-dropdown-menu-title-content{
    /* border-bottom: 1px solid #73C7FF; */
    border-bottom: none;
    border-radius: 0;
}
.drop-people .ant-dropdown-menu li:nth-child(2){
    padding-top: 0;
    padding-bottom: 0;
}
.searcher-add-artists{
  padding-left: 5px;
  padding-right: 5px;
}
.search-add{
    margin-bottom: 0px;
    padding: 0;
    min-height: 20px;
    height: 23px;
}
.search-add.plus-add{
  border-radius: 0px !important;
  background: #fff !important;
  border: none !important;
}
.search-add.plus-add input{
  background: transparent;
  padding-left: 5px;
  font-size: 13px;
}
.search-add input{
    font-size: 10px;
    border: none;
    color: #898686;
}
.search-add .ant-input-group-addon .ant-input-search-button{
    position: absolute;
    right: 1px;
    top: 6px;
    border: none;
    background: #0000;
    z-index: 1;
    width: 35px;
}
.search-add .ant-input-group-addon button{
    width: 10px !important;
    height: 10px;
}
.search-add.plus-add .ant-input-group-addon{
  display: none;
}
.search-add.plus-add:focus{
  box-shadow: none;
}
.search-add.plus-add .ant-input:focus{
  box-shadow: none;
}
.ant-dropdown-menu .add-list li:nth-child(2){
  border-top: none;
}
.submenu-add{
    width: 100%;
    display: flex;
    justify-content: right;
}
.submenu-add .add-btn{
    margin: 0;
    height: 25px;
    padding: 6px 1px 0 5px;
    font-size: 10px;
    min-height: 20px;
    background: none;
    font-weight: 500;
    color: var(--subtext);
    width: fit-content;
    max-width: min-content;
    box-shadow: none;
    font-size: 11px;
    font-weight: 600;
}
.submenu-add .add-btn.new{
  background: linear-gradient(135deg, var(--outrageouse-orange), #ff4500, #ff7f50);
  color: #fff;
  padding: 6px 7px;
  margin-left: 8px;
}
.submenu-add .add-btn.new:hover {
  filter: brightness(1.1);
  color: #fff;
}
.submenu-add .add-btn:hover {
    color: var(--outrageouse-orange);
    box-shadow: none;
}
.participant-cd{
    width: 100%;
    padding-left: 0;
    min-height: 80px;
    margin-bottom: 0px;
    list-style-type: none;
}
.participant-cd .card-data{
    height: 65px;    
    min-width: 160px;
    max-width: 160px;
    padding: 7px 9px;
    position: relative;
    margin-right: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: inline-block;
    border: 1px solid #E9E9E9;
}
.participant-cd .new-artist .img{
    width: 30px;
    height: 30px;
    border-radius: 50px;
}
.participant-cd .new-artist .artist-icon{
    width: 30px;
    height: 30px;
    fill: #d7d7d7;
    vertical-align: middle;
    display: inline-block;
}
.check-artists{
    font-size: 10px;
    padding-top: 3px;
    color: var(--subtext);
}
.check-artists .general-switch{
    height: 12px;
    min-width: unset;
    width: 22px;
    margin-top: 0px;
    margin-left: 37px;
    margin-right: 5px;
}
.check-artists .ant-switch-handle{
    width: 8px;
    height: 8px;
}
.check-artists .ant-switch-checked .ant-switch-handle{
    left: calc(100% - 10px);
}
.new-artist .role-disp {
    display: block;
    font-size: 10px;
    font-weight: bold;
    margin-left: 39px;
    max-width: 89px;
    overflow-x: hidden;
    white-space: nowrap;
    padding-right:4px;
    text-overflow: ellipsis;
    color: var(--subtext);
}
.show-roles{
    display: flex;
    justify-content: flex-start;
}
.show-roles .edit-roles {
    color: #BFBFBF;
    font-size: 13px;
    cursor: pointer;
}
.del-participant{
    top: 2px;
    right: 2px;
    width: 15px;
    height: 15px;
    position: absolute;
    align-items: center;
}
.del-participant svg{
    color: #E9E9E9;
    font-size: 15px;
    cursor: pointer;
}
.preview-rel{
    padding-bottom: 20px;
    display: flex;
}
.preview-rel .prev-img{
    height: 135px;
    border-radius: 5px;
    margin-right: 30px;
}
.info-displayed h2{
    font-weight: 800;
    margin-bottom: 1px;
    font-size: 19px;
}
.info-displayed h3{
    font-size: 17px;
    padding-bottom: 8px;
}
.info-displayed .listed{
    font-size: 13px;
}
.info-displayed .listed span{
    font-size: 12px;
    color: var(--subtext);
}
.no-data{
    color: #c3c3c3;
    padding: 10px 0;
    font-style: italic;
    text-align: center;
}
.alert-artist{
    color: var(--malibu-orig);
    padding-top: 9px;
    font-size: 15px;
    font-weight: 600;
}
.tabs-top.tab-artist .ant-tabs-tab-btn{
  color: var(--color-black);
  font-size: 20px;
  text-align: center;
}
.tabs-top.tab-artist .ant-tabs-tab-disabled{
  cursor: auto;
}
.tabs-top.tab-artist .ant-tabs-tab-disabled .ant-tabs-tab-btn{
  width: auto;
  color: rgba(155, 155, 155, 0.9);
  font-size: 24px;
  line-height: initial;
}
/********* Participants - Add Artist *********/
.add-form{
    padding-top: 0;
}
.add-form .add-artist{
    padding-top: 20px;
}
.add-form .dsp-info-circ{
    top: 40px;
    right: 3px;
    color: #808080;
    font-size: 15px;
    position: absolute;
}
.new-artist-add{
  padding: 0;
  width: auto;
  border: none;
  height: 39px;
  cursor: pointer;
  min-width: 75px;
  font-size: 14px;    
  font-weight: 500;
  background: none;
  border-radius: 5px;
  text-transform: capitalize;
}
.new-artist-add.clicked:hover, .new-artist-add.clicked:focus, .new-artist-add.clicked:active{
    /* border-radius: 3px; */
    /* border: 0.5px solid #F0F0F0; */
    /* box-shadow: 0px 0px 3px 1px rgb(115 199 255 / 38%); */
    /* remove shadows */
    box-shadow: none;
    /* font-weight: 600; */
    color: #000;

}
.new-artist-add.clicked:focus-visible {
  outline: unset !important;
}
.new-artist-add b{
    font-size: 25px;
    font-weight: bold;
    vertical-align: middle;
    color: var(--outrageouse-orange);
}
.add-list{
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0px;
  min-height: 50px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.add-list li{
    font-size: 14px;
    margin-top: 6px;
    list-style: none;
    padding-left: 8px;
}
.add-list li:hover {
    color: #000;
    transition: 0.2s;
}

.add-list li::marker{
    font-size: 20px;
}
.new-artist .name-added{
    width: 104px;
    height: 17px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    padding-left: 8px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
}
.new-artist-add.has-error{
    border: 1px solid #FD6A88;
}
.new-artist, .relative-input{
    position: relative;
}
.modalAdd-btns{
    text-align: right;
    padding-right: 20px;
}
.menu-cd{
    top: 0;
    opacity: 0;
    width: 151px;
    height: 151px;
    display: flex;
    visibility: hidden;
    position: absolute;
    border-radius: 10px;
    align-items: center;
    background: #000000a6;
    justify-content: space-evenly;
    transition: visibility 0s, opacity 0.3s linear;
    -o-transition:  visibility 0s, opacity 0.3s linear;
    -moz-transition: visibility 0s, opacity 0.3s linear;
    -webkit-transition: visibility 0s, opacity 0.3s linear;
}
.new-artist:hover .menu-cd{
    opacity: 1;
    visibility: visible;
    margin-left: 0px;
}
.new-artist .album{
    width: 151px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 7px;
}
.new-artist .artist{
    font-size: 10px;
    color: var(--subtext);
    font-weight: 500;
    bottom: 8px;
}
.menu-cd svg{
    color: #fff;
    font-size: 25px;
    cursor: pointer;
}
.ant-select-show-search.ant-select.select-role:not(.ant-select-customize-input) .ant-select-selector{
    background: #f8f8f847;
    cursor: pointer;
}
.rolesicon svg{
    color: var(--outrageouse-orange);
    font-size: 13px;
    margin-right: 5px;
}
.ant-select-auto-complete.autocomp-general .ant-select-selector{
    color: #898686;
    min-height: 40px;
    border-radius: 5px !important;
    margin-bottom: 15px;
}
.ant-select-auto-complete.autocomp-general .ant-select-selector input{
    min-height: 40px !important;
}
.autocomp-general .ant-select-selection-placeholder{
    padding: 5px 0 !important;
}
.autocomErr.autocomp-general .ant-select-selector{
    box-shadow: 0px 0px 5px 1px #FD6A88 !important;
}
/********* Tracks *********/
.track-details{
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}
.track-details svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.track-title{
    padding-top: 15px;
    margin-bottom: 0;
}
.pause-song{
    width: 17px;
    height: 17px;
    color:  #878787;
    cursor: pointer;
}
.track-details .emptyIcon{
    width: 20px;
}
.forms.tracks{
    max-width: 100%;
    overflow-x: hidden;
    max-height: 545px;
    overflow-y: auto;
}
.forms.tracks .ant-table-wrapper{
    overflow-x: auto;
    padding-bottom: 10px;
}
.table-tracks table{
    text-align: center;
}
.table-tracks table thead th{
    color: #9B9B9B;
    font-size: 13px;
    padding-top: 0px;
    text-align: center;
    padding-bottom: 5px;
    background: transparent;
}
.table-tracks table tr td{
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* .table-tracks table tr:nth-child(odd) td{
    background-color: #F1F1F1;
} */
.tracks .add-track{
    width: 45px;
    height: 45px;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    background-color: #f0f0f0e6;
}
.tracks .add-track span{
    align-self: center;
}
.forms.trackAdd{
    padding: 0;
}
.add-plubisher{
    display: flex;
    margin-top: 7px;
    flex-direction: column;
    justify-content: center;
}
.add-plubisher .add-icon{
    width: 40px;
    height: 40px;
    display: flex;
    margin-left: 13px;
    border-radius: 5px;
    flex-direction: column;
    background: rgba(240, 240, 240, 0.9);
}
.add-icon svg{
    margin: auto;
    font-size: 11px;
}
.trackAdd .audio{
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}
.trackAdd .audio svg{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.audio-preview{
    width: 100%;
    height: 40px;
}
.audio-preview::-webkit-media-controls-panel{
    background: #fff;
}
.audio-preview::-webkit-media-controls-timeline{
    padding: 5px;
    margin-left: 10px;
    margin-right: 8px;
    border-radius: 20px;
    /* filter: brightness(4) saturate(3.5); */
    /* background: var(--outrageouse-orange); */
}
.audio-preview::-webkit-media-controls-play-button{
    opacity: 0.9;
    margin-right: 10px;
    border-radius: 50px;    
    /* filter: hue-rotate(180deg) invert(1); */
    /* background-color: var(--outrageouse-orange); */
}
.audio-preview::-webkit-media-controls-volume-slider{
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 25px;
}
.backbtn{
    background-color: #7E7E8F;
}
.backbtn:hover, .backbtn:focus, .backbtn:active{
    color: #fff;
    opacity: 0.9;
    cursor: pointer;
    filter: brightness(1.3);
    background-color: #7E7E8F;
}
/********* Territories *********/
.general-switch{
    margin-left: 20px;
    margin-right: 15px;
}
.general-switch.ant-switch-checked{
    background:  var(--malibu);
}
.sub-trr{
    color: #9B9B9B;
    padding-top: 9px;
}
.sub-trr a{
    color: unset;
}
.sub-trr b:hover{
    cursor: pointer;
    filter: brightness(0.7);
}
.sub-trr span {
    font-weight: bold !important;
}
.territories-box{
    display: flex;
}
.check-div{
    width: 50%;
    overflow: auto;
    margin-top: 18px;
    max-height: 300px;
    border: 1px solid #F0F0F0;
}
.check-div .ant-checkbox-group-item{
    margin-right: 0;
}
.left-check{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.right-check{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.check-all{
    font-weight: 600;
    background: #FAFAFA;
}
.check-country .ant-checkbox-group-item, .check-all{
    width: 100%;
    padding-top: 11px;
    padding-left: 16px;
    padding-bottom: 11px;
    border-bottom: 1px solid #F0F0F0;
}
.check-country .ant-checkbox-group-item .ant-checkbox, .check-all .ant-checkbox{
    padding-right: 10px;
}
/********* Distribution *********/
.dist h2{
    margin: 0;
}
.dist .group-dsp{
    width: 100%;
    overflow-y: auto;
    max-height: 510px;
    text-align: center;
    border: 1px solid #F0F0F0;
    border-radius: 20px;
    margin-top: 7px;
    padding-top: 20px;
}
.dist .group-dsp .ant-checkbox-group-item{
    width: 145px;
    height: 88px;
    margin: auto;
    text-align: center;
    margin-bottom: 35px;
    position: relative;
}
.dsp-label{
    min-width: 125px;
    text-align: center;
}
.logo-distro{
    height: 66px;
    margin: auto;
    display: block;
    max-width: 66px;
    margin-bottom: 8px;
}
.check-all-dsp{
    padding-top: 10px;
    padding-bottom: 25px;
}
.check-all-dsp .ant-checkbox + span{
    padding-left: 16px;
    font-weight: 600;
}
.check-distro .ant-checkbox + span{
    padding-left: 6px;
    width: 100%;
    text-align: center;
}
.check-distro{
    text-align: left;
    width: 100%;
}
.request-dsp{
    left: 23px;
    top: 90px;
    font-size: 10px;
    position: absolute;
    color: var(--outrageouse-orange);
}
.dsp-error {
    color: #FD6A88;
    font-size: 11px;
    font-weight: bold;
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    top: 13px;
}
.addPreviewImage{
    width: 113px;
    height: 113px;
}
p.colaboratorListElement{
    margin: 0;
    height: 100%;
    padding: 11px 0;
    font-size: 1.2em;
}
.upload-text-error{
    color: #FD6A88;
    font-size: 11px;
    font-weight: bold;
    position: absolute;
}
.tiers h2{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}
.prices{
    margin-bottom: 40px;
}
.prices .ant-table-tbody>tr>td{
    padding-top: 9px;
    padding-bottom: 9px;
}
.tabs-top .ant-tabs-nav{
    margin-bottom: 25px;
}
.tabs-top .ant-tabs-ink-bar{
    background: var(--outrageouse-orange);
}
/********* Success *********/
.success h2{
    padding-top: 15px;
}
.success-desc{
    height: 166px;
}
.img-sc{
    bottom: 0;
    left: 107px;
    position: absolute;
}
/********* Medias *********/
@media(max-width: 615px){
    .table-tracks table tr td:nth-child(5){
        min-width: 125px;
    }
    .success-desc{
        height: auto;
    }
    .img-sc{
        width: 100%;
        margin: 20px 0;
        position: unset;
    }
    .preview-rel{
        display: block;
        text-align: center;
    }
    .preview-rel .prev-img{
        padding-left: 0px;
        padding-right: 0px;
        margin-right:0px;
    }
    .info-displayed{
        padding-top:15px;
    }
    .info-displayed .listed{
        text-align: left;
    }
}
/********* Media *********/
@media (max-width: 767px){
    .add-form .dsp-info-circ{
        right: -23px;
    }
    .dsp-form{
        margin-left: 10px;
        margin-right: 20px;
    }
}
@media(max-width: 700px){
    .territories-box{
        display: block;
    }
    .check-div{
        width: 100%;
    }
    .check-div.right-check{
        margin-top: 45px;
    }
}
@media(max-width: 435px){
    .create-box .cust-territory{
        max-width: 240px;
    }
    h2.titl-section{
        font-size: 15px;
    }
    .btnsm{
        padding-right: 0;
        font-size: 11px;
    }
    .new-artist-add b{
        font-size: 15px;
    }
}
@media(max-width: 410px){
    .dsp-label{
        min-width: 113px;
    }
    .dist .group-dsp{
        max-height: 485px;
    }
    .create-box{
        padding: 40px 24px 31px 24px;
    }
}
@media (min-width: 766px) and (max-width: 786px){
    .advance-info{
        min-width: 678px;
    }
}
@media (max-width: 765px){
    .advance-info{
        margin-right: -30px;
        min-width: 100%;
    }
}
@media (max-width: 472px){
    .participant-cd .card-data{
        min-width: 100%;
        max-width: 100%;
    }
    .new-artist .name-added{
        max-width: 60%;
        width: 100%;
    }
    .new-artist .role-disp{
        max-width: 80%;
    }
    .order-btns{
        display:flex;
    }
}