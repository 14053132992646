.region-box{
    padding-left: 70px;
    padding-right: 70px;
}
.region-box .title{
    display: flex;
	flex-wrap: wrap;
    width: 100%;
    text-align: left;
    padding: 50px 0px 20px;
}
.region-box .description {
    color: #A5A5A5;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 18px;
    text-align: left;
}
.region-box .check-div{
    background: #fff;
    margin-bottom: 50px;
    max-height: 323px;
}
/********* Media *********/
@media (max-width: 575px){
    .region-box{
        padding-left: 35px;
        padding-right: 35px;
    }
}