.container-box.pwd-new .ant-row .ant-col{
    padding-bottom: 20px;
}
.container-box.pwd-new  .sign-box{
    text-align: left;
}
.container-box.pwd-new .ant-row .ant-col span.pwd{
    margin-top: 8px;
}
.error-pwd-token{
    color: #FD6A88;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 20px;
}
