.modalFP .ant-modal-content{
    max-width: 550px;
    padding-left: 12px;
    padding-right: 12px;
}
.modalFP .ant-modal-title{
    font-size: 22px;
    padding-top: 18px;
    font-weight: 600;
}
.recover-desc{
    font-size: 13px;
    padding-bottom: 6px;
    color: #717171;
}
.recover-desc .txt-sent{
    font-size: 16px;
    padding-bottom: 15px;
}
.forgot-pwd{
    text-align: right;
    position: relative;
    bottom: 14px;
}
.forgot-pwd a{
    color: #8C8C8C;
    font-size: 12px;
}
.forgot-pwd a:hover{
    color: #b4b4b4;
}
.link-resend{
    color: var(--outrageouse-orange);
}
.resend-txt{
    display: inline;
}