#release-detail-container .edit_button {
  width: 100px;
  height: 45px;
  padding: 10px;
  border: none;
  background: #f6f6f6;
  padding: 10px;
  border-radius: 30px;
}
#release-detail-container .edit_button_container {
  text-align: right;
  padding: 0 70px;
}
#release-detail-container{
  padding: 35px 0;
}
#release-detail-container .active {
  background-color: rgba(244, 92, 46, 0.15) !important;
}

#release-detail-container .edit_button{
  color: var(--text-color);
}
#release-detail-container .edit_button, #release-detail-container .edit_button svg{
  transition: all 0.3s ease-in-out;
  opacity: 0.7;

}
#release-detail-container .edit_button svg{
  vertical-align: text-bottom;
  margin-left: 10px;
}

#release-detail-container .edit_button:hover,
#release-detail-container .edit_button:focus {
  opacity: 1;
}
/************ UPC assign ************/
.assign-upc {
  background: linear-gradient(
    135deg,
    var(--outrageouse-orange),
    #ff4500,
    #ff7f50
  );
  border-radius: 8px;
  width: auto;
  font-weight: 800;
  border: none;
  vertical-align: middle;
}
.assign-upc[disabled] {
  background-color: #efefef;
  box-shadow: none !important;
}
.sping-assign{
  height: 32px;
  width: 77px;
  line-height: 0;
}
.sping-assign .ant-spin-dot-item{
  background-color: var(--outrageouse-orange);
}
.upc-note{
  padding-top: 30px;
  font-size: 13px;
  color: var(--outrageouse-orange);
}
.opt-newupc{
  color: #6e6e6e;
}
.inline-input .selector-general.editupc{
  max-width: 77%;;
}
/************ id's ************/
#format-code {
  position: absolute;
  z-index: 2;
  right: 25px;
}

#release-detail-container button.edit_button::after {
  border: none !important;
  opacity: 0;
  box-shadow: none !important;
}
@media (max-width: 575px) {
  #release-detail-container .edit_button_container{
    padding: 0 35px;
  }
}