.actions-box {
  padding: 70px;
}

.actions-box.fixed {
  padding: 15px;
  position: fixed;
  bottom: 0;
  right: 70px;
  background-color: #fff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.actions-box.fixed .actions-release {
  padding-bottom: 0;
}

.actions-release {
  padding-bottom: 40px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.actions-release .general-btn {
  margin-left: 30px;
  margin-right: 0px;
  text-transform: capitalize;
  border-radius: 48px;
  min-width: 170px;
  min-height: 45px;
  font-size: 16px;
  white-space: pre-wrap;
  height: auto;
}

.actions-release .general-btn.return {
  background: #fff;
  border: 1px solid #7e7e8e;
  color: #7e7e8e;
  box-shadow: 0 4px 14px 0 rgb(178 175 174 / 39%);
}

.actions-release .general-btn.return svg {
  vertical-align: top;
  position: absolute;
  left: 18px;
}
.actions-release .general-btn.reject {
  background: #7e7e8e;
  box-shadow: 0 4px 14px 0 rgb(178 175 174 / 39%);
}
.actions-release .general-btn.save {
  background: linear-gradient(135deg, #1e90a9, #197d93, #25afcd);
  box-shadow: 0 4px 14px 0 rgb(29 144 169 / 39%);
}
.actions-release .general-btn.save :hover::before{
  transform: translate(-50%, -50%) scale(1);
}

.actions-release .general-btn.save:hover, .actions-release .general-btn.save:focus, .actions-release .general-btn.save:active, .contract-input .createpayee-btn button:hover, .createcontract-btn button:hover {
  color: #fff;
  background: linear-gradient(135deg, #25afcd, #197d93, #1e90a9);
  box-shadow: 0 6px 20px 0 rgba(29, 144, 169, 0.45);
  opacity: 0.9;
}
.actions-release .in-review-text {
  margin-left: 30px;
  margin-right: 0px;
  text-transform: capitalize;
  min-width: 142px;
  height: 65px;
  font-size: 16px;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #abababd9;
}
.actions-release .view-fingerprint-release{
  margin-left: 30px;
  margin-right: 0px;
  text-transform: capitalize;
  border-radius: 48px;
  min-width: 170px;
  min-height: 45px;
  font-size: 16px;
  white-space: pre-wrap;
  height: 45px;
  background: #f49700;
  font-weight: 800;
  color: #fff;
  padding: 9px 0;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  display: inline-block;
  box-shadow: 0 4px 14px 0 rgb(178 175 174 / 39%);
}
.actions-release .view-fingerprint-release span{
  font-size: 14px;
  margin-right: 5px;
  vertical-align: text-top;
}

/**************** Medias ****************/
@media (max-width: 760px) {
  .actions-release {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  .actions-release .general-btn.return {
    width: 48%;
    margin-left: 0;
  }
  .actions-release .general-btn.reject {
    width: 48%;
    margin-left: 15px;
  }
  .actions-release .general-btn.grn {
    width: 100%;
    margin-left: 0px;
  }
  .actions-release .in-review-text {
    text-align: center;
  }
  .actions-release .view-fingerprint-release{
    width: 48%;
    margin-left: 15px;
    margin-top: 10px;
  }
}
@media (max-width: 467px) {
  .actions-release .general-btn.return {
    width: 100%;
    margin-left: 0;
    text-wrap: wrap;
    height: auto;
  }
  .actions-release .general-btn.reject {
    width: 100%;
    margin-left: 0px;
    text-wrap: wrap;
    height: auto;
  }
  .actions-release .view-fingerprint-release{
    width: 100%;
    margin-left: 0px;
    text-wrap: wrap;
    height: auto;
  }
}
@media (max-width: 575px) {
  .actions-box {
    padding-left: 35px;
    padding-right: 35px;
  }
}
