.link-table{
  color: var(--malibu-orig);
  text-decoration: underline;
}
.edit-value-icon {
  cursor: pointer;
}
.edit-value-icon .open-edit{
  margin-left: 10px;
}
.item-drop-contracts{
  padding: 10px;
  cursor: default;
}
.group-drop-contracts{
  display: flex;
  justify-content: space-between;
  align-items: flex-end !important;
  cursor: default;
}
.drop-contract{
  width: 550px;
  z-index: 1000;
}
.group-drop-contracts .relative-input{
  margin-bottom: 15px;
}
.group-drop-contracts .new-rel-btn.save{
  max-width: 100px;
  width: 100px;
  min-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.group-drop-contracts .new-rel-btn.save span{
  width: unset;
}
.list-contracts{
  padding: 0px;
  font-size: 13px;
}
.list-contracts svg{
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
}
.yes-ac{
  background-color: #00CF85;
  color: #fff;
  max-width: 50px;
  text-align: center;
  /* margin: auto; */
  border-radius: 6px;
  padding: 3px 0;
}
.no-ac{
  background-color: #ff3b0026;
  color: #F45C2E;
  max-width: 50px;
  text-align: center;
  /* margin: auto; */
  border-radius: 6px;
  padding: 3px 0;
}
.pend-status{
  background: #ffb700;
  color: #fff;
  border-radius: 10px;
  padding: 3px 13px;
  max-width: 103px;
  text-align: center;
}
.sync-status{
  background: #46bdc6;
  color: #fff;
  border-radius: 10px;
  padding: 3px 15px;
  max-width: 103px;
  text-align: center;
}
.errorcurve{
  box-shadow: 0px 0px 6px var(--error-field);
}
.errorCurve-text{
  position: absolute;
  bottom: 5px;
  color: var(--error-field);
  font-size: 12px;
  font-weight: 700;
}
.errorPayee-text {
  position: absolute;
  top: 127px;
  color: var(--error-field);
  font-size: 12px;
  left: 22px;
  font-weight: 700;
}
.content-table-admin.contracts .ant-table-container{
  min-height: 589.22px;
}
.content-table-admin.contracts .ant-table table .ant-empty{
  min-height: 507.22px;
  align-content: center;
}
.content-table-admin.contracts td.title-category.edit-vals{
  text-align: center;
}
.nodata-contracts{
  opacity: 0.5;
}
.spin-contract{
  min-height: 290.22px;
}
.spin-contract.empty{
  align-content: center;
}
.spin-contract .ant-spin-dot-item{
  background-color: var(--outrageouse-orange);
}
/********* Dropdown **********/
.contract-card{
  border: 1px solid #eeeeee;
  border-radius: 20px;
  box-shadow: var(--shadow-elevation-high);
  margin-bottom: 20px;
}
.contract-card .ant-card-body{
  padding-left: 0px;
  padding-right: 10px;
}
.input-edit{
  margin-bottom: 25px;
  min-height: 44px;
  min-width: 230px !important;
}
.input-edit.ant-input::placeholder{
  color: #C4C4C4;
}
.contract-item{
  padding: 5px 0;
}
.contract-input{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}
.contract-input .relative-input {
  margin-left: 20px;
  margin-right: 10px;
}
.drop-status .item-priority.unclick{
  cursor: default;
}
.contract-input .createpayee-btn, .createcontract-btn{
  margin: auto 15px;
  display: inline-block;
  height: 44px;
}
.contract-input .createpayee-btn button, .createcontract-btn button{
  margin-top: 0px;
  box-shadow: 0 4px 14px 0 rgb(29 144 169 / 39%);
  background: linear-gradient(135deg, #1e90a9, #197d93, #25afcd);
}
.contract-input .new-rel-btn, .contract-input .createpayee-btn{
  max-width: 200px;
  width: 100%;
  margin: auto 10px auto 10px;
}
.contract-input .createpayee-btn{
  margin-top: 25px;
}
.contract-input .new-rel-btn.save{
  margin-left: 20px;
}
.contractadd-input{
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}
.contractadd-input .new-rel-btn.save{
  margin: 0px 20px;
}
.container-admin .add-title{
  display: flex;
  padding: 35px 0px 20px;
  align-items: center;
  justify-content: space-between;
}
.container-admin .add-title h2{
  margin: 0;
  align-content: center;
  font-size: 26px;
}
/********* Sublabels *********/
.content-subtable table thead th{
  background: #E9E9E9;
  padding: 10px 0 !important;
}
.content-subtable .ant-table{
  border: none;
  border-radius: 0;
}
.content-subtable .ant-spin-container{
  background: #E9E9E9;
}
.searcher-cont .relative-input {
  padding-bottom: 15px;
  width: 100%;
  max-width: 230px;
  margin-right: 16px;
  display: inline-block;
  vertical-align: top;
}
.searcher-cont .relative-input input {
  max-width: 195px;
}
/********* Modal Disable Status *********/
.ant-modal-wrap  .modalConfirmContract {
  z-index: 1051;
}
/********* Medias *********/
@media (max-width: 865px){
  .contract-card .ant-card-body{
    padding: 24px 35px;
  }
  .contract-input .relative-input{
    margin-left: 0px;
    margin-right: 0px;
  }
  .contract-input .new-rel-btn.save{
    margin: auto;
    max-width: 100%;
    min-width: unset;
  }
  .contract-input .createpayee-btn{
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .contract-input .createpayee-btn button{
    margin: auto;
    width: 100%;
    max-width: 100%;
    min-width: unset;
  }
}
@media (max-width: 788px){
  .contractadd-input .input-edit{
    min-width: 100% !important;
  }
}
@media (max-width: 695px) and (min-width: 551px){
  .container-admin .add-title{
    display: block;
    text-align: center;
  }
  .container-admin .add-title h2{
    margin-bottom: 15px;
  }
}
@media (max-width: 684px){
  .contract-input {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
  .input-edit{
    min-width: 100% !important;
  }
  .contractadd-input{
    display: block;
  }
  .contractadd-input .new-rel-btn.save{
    min-width: 100%;
    max-width: 100%;
    margin: 0 0 20px;
  }
}
@media (max-width: 595px) and (min-width: 551px) {
  .contract-input{
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
  }
  .contract-card .ant-card-body{
    /* min-width: 223px; */
    padding: 24px 23px;
  }
}
@media (max-width: 322px){
  .contract-input {
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }
}