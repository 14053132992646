body {
  margin: 0;
  font-family: Geomanist;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Geomanist;
}

:root {
  --shadow-color: 0deg 0% 76%;
  --shadow-elevation-low:
    0.3px 0.4px 0.4px hsl(var(--shadow-color) / 0.4),
    0.4px 0.5px 0.5px -2.4px hsl(var(--shadow-color) / 0.27),
    1px 1.3px 1.3px -4.7px hsl(var(--shadow-color) / 0.15);
  --shadow-elevation-medium:
    0.3px 0.4px 0.4px hsl(var(--shadow-color) / 0.33),
    0.4px 0.5px 0.5px -1.2px hsl(var(--shadow-color) / 0.27),
    0.9px 1.2px 1.2px -2.4px hsl(var(--shadow-color) / 0.21),
    2.2px 3.1px 3px -3.6px hsl(var(--shadow-color) / 0.14),
    4.8px 6.6px 6.4px -4.7px hsl(var(--shadow-color) / 0.08);
  --shadow-elevation-high: 0 3px 3px hsl(0deg 0% 0% / 0.01),
  0 4px 4px hsl(0deg 0% 0% / 0.007);
    --shadow-header: 0 1px 1px hsl(0deg 0% 0% / 0.015), 0 1px 1px hsl(0deg 0% 0% / 0.005), 0 2px 2px hsl(0deg 0% 0% / 0.005), 0 3px 3px hsl(0deg 0% 0% / 0.005), 0 4px 4px hsl(0deg 0% 0% / 0.005);
}

.fadein { 
  -webkit-animation: fadeIn 750ms;
  -moz-animation: fadeIn 750ms;
  -o-animation: fadeIn 750ms;
  animation: fadeIn 750ms;
 }

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}