#upload-card {
  position: fixed;
  bottom: 30px;
  left: calc(50% - 300px);
  max-height: 210px;
  width: 600px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;
}

#upload-card .ant-card-body {
  overflow-y: auto;
  max-height: 150px;
}
