.bar-progress{
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    background: #E9E9E9;
    height: 6px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.bar-progress .bar{
    width: 20%;
    position: relative;
    bottom: 9px;
    display: inline-block;
    height: 6px;
}
.bar-progress .bar.left{
    border-top-left-radius: 10px;
}
.bar-progress .bar.right{
    border-top-right-radius: 10px;
}
@keyframes fillLeft {
    0% { width: 0; }
    100% { width: 20%; }
}
.filled{
    background-color: var(--outrageouse-orange);
    animation: fillLeft 1.5s ease-in-out;
    animation-fill-mode:both;
}
@keyframes emptyBar {
    0% { width: 25%; }
    100% { width: 0; }
}
.empty-bar{
    background-color: var(--outrageouse-orange);
    animation: emptyBar 1.5s ease-in-out;
    animation-fill-mode:both;
}
/********* Create Bar *********/
.bar-text{
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 32px;
    display: flex;
    color: #9B9B9BE5;
    background: #3C3C3C;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.bar-text .bar{
    position: relative;
    bottom: 0px;
    width: 14.28%;
    cursor: pointer;
    padding: 5px 0px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    text-transform: capitalize;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.bar-text .bar.left{
    border-top-left-radius: 37px;
}
.bar-text .bar.right{
    border-top-right-radius: 20px;
    cursor: default;
}
.bar-text .bar.last-step{
    animation: fillLast 0.1s forwards;
    background: var(--outrageouse-orange);
}
.bar-text .bar.left.last-step{
    border-top-left-radius: 20px;
}
@keyframes fillLast {
    100%{
        color: #fff;
        box-shadow: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.bar-text .bar div {
    margin: auto;
    padding: 0 5px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topBar{
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 0px;
    height: 32px;
    /* box-shadow: 6px 0px 0px #ff6f43; */
    border-radius: 74px 100px 100px 0px;
    background-color: #ff6f43;
    -webkit-transition: width 1s;
    transition: width 1s;
}
/********* Filling Orange *********/
@keyframes colorWhite {
    100%{
        z-index: 2;
        color: #fff
    }
}

.stepFilled {
    width: 12.5%;
}

.stepFilled1 {
    width: 25%;
}

.stepFilled2{
    width: 37.5%;
}

.stepFilled3{
    width: 50%;
}

.stepFilled4{
    width: 62.5%;
}

.stepFilled5{
    width: 75%;
}

.stepFilled6{
    width: 87.5%;
}

.stepFilled7{
    width: 100%;
    animation: cleanBar 0.1s forwards;
}

@keyframes cleanBar {
    100%{
        box-shadow: none;
        border-radius: 12px 12px 0px 0px;
    }
}
.fillStep{
    animation: colorWhite 0.3s forwards;
}
/********** User Menu **********/
.drop-user .ant-dropdown-menu-item:hover{
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-active{
  background-color: #0000;
}
.drop-user ul li.ant-dropdown-menu-item{
  padding: 7px 12px 3px;
}
/********** Medias **********/
/* @media(max-width: 910px){
    .bar-text .bar div {
        margin: auto;
        line-height: 2;
        max-width: 45px;
        font-size: 12px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
} */
@media(max-width: 745px) and (min-width: 551px){
  .bar-text .bar{
    width: 13%;
  }
  .bar-text .bar div {
    max-width: 37px;
    font-size: 11px;
  }
}
@media(max-width: 400px){
    .bar-text .bar div {
        margin: auto;
        line-height: 2;
        max-width: 35px;
        font-size: 10px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
