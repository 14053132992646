body, html {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5 !important;
  margin: 0;
  padding: 0;
}
body{
  overflow-y: auto;
  position: relative;
}
*,
::after,
::before {
  box-sizing: border-box;
}
head {
  background-color: #f0f2f5;
}
#root, .body, html {
  color: #454545;
  background-color: #f0f2f5;
  font-family: Geomanist !important;
}
#root {
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
:root {
  --malibu: #ff8f6d;
  --malibu-orig: #73c7ff;
  --martinique: #2c2b47;
  --white-text: #fbfbfb;
  --lavender-rose: #ff9fff;
  --color-container: #f6f6f6;
  --color-black: #1c1c1c;
  --outrageouse-orange: #ff5f2e;
  --color-title: #45454599;
  --subtext: #454545b3;
  --error-field: #f83636;
  --success: #00CF85;
}
.container {
  flex: 1;
  padding: 20px;
  min-height: 100%;
}
.container-box .logo {
  height: auto;
  max-width: 300px;
  width: 100%;
}
.content {
  flex: 1 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.content-margin{
  margin-top: 70px;
  padding-left: 250px;
  transition: padding-left 0.3s;
  flex: 1 0 auto; /* This ensures content takes available space */
}
.dashboard-full{
  margin-top: 70px;
  padding-left: 81px;
  transition: padding-left 0.3s;
  flex: 1 0 auto; /* This ensures content takes available space */
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.ant-input-suffix .anticon.anticon-eye svg path:nth-child(3),
.ant-input-suffix .anticon.anticon-eye svg path:nth-child(4) {
  fill: var(--malibu-orig);
}
.circle-alert {
  width: 18px;
  height: 18px;
  margin-left: 20px;
  vertical-align: middle !important;
}
.circle-alert svg{
  font-size: 18px !important;
  color: var(--outrageouse-orange);
}
.ant-modal,
.ant-select-dropdown,
.ant-picker-dropdown,
.ant-dropdown,
.ant-message,
.ant-notification,
.ant-drawer,
.ant-transfer,
.ant-popconfirm,
.ant-card,
.royalty-graph {
  font-family: Geomanist;
}

.alert-p {
  width: 18px;
  height: 18px;
  position: absolute;
  align-content: center;
  right: 7px;
  top: 8px;
}
.alert-p svg {
  font-size: 18px !important;
  color: var(--outrageouse-orange);
}
/********* Global Btns *********/
.btn-click {
  cursor: pointer;
}
.sm {
  width: 100%;
  max-width: 80px;
}
.md {
  width: 100%;
  max-width: 150px;
}
.lg {
  width: 100%;
  max-width: 220px;
}
.fullWidth {
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.m-auto {
  margin: 0 auto;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.mr-3 {
  margin-right: 1.5rem;
}
.mr-4 {
  margin-right: 2rem;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.ml-4 {
  margin-left: 2rem;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.center {
  text-align: center;
}

.ant-pagination-item-link {
  border-radius: 10px !important;
}

.ant-pagination-item-active {
  border-radius: 10px !important;
}

.ant-pagination-item {
  border-radius: 10px !important;
}

.table-admin .ant-table-tbody > tr > td:first-child {
  border-left: 3px solid transparent;
}
/********* medias *********/
@media(max-width: 550px){
  .content-margin{
    padding-left: 0px;
  }
  .dashboard-full{
    padding-left: 0px;
  }
  body {
    overflow-y: auto;
  }
  #root {
    min-height: 100vh;
    height: auto;
  }
  .content {
    padding-bottom: 100px; /* Increase padding for mobile */
  }
}