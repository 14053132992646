.rights-box{
    padding-left: 70px;
    padding-right: 70px;
}
.rights-box .title{
    display: flex;
	flex-wrap: wrap;
    width: 100%;
    text-align: left;
    padding: 40px 0px;
}
.title-edit-right{
    color: #A5A5A5;
    font-size: 15px;
    position: unset;
    font-weight: 600;
    padding-bottom: 2px;
    min-width: max-content;
    width: 100%;
}
.rights-box .title h1, .region-box .title h1, .requested-box .title h1 {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    display: inline-block;
    margin-right: 30px; 
}
.side-mark-icon {
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: #ffffff;
    padding: 10px;
    border-radius: 50px;
    font-size: 15px;
    margin-right: 15px;
}
.rights-box .mark-icon, .region-box .mark-icon, .requested-box .mark-icon{
	display: inline-block;
	cursor: pointer;
	width: 40px;
    height: 40px;
    background: #FFF;
    padding: 10px;
    border-radius: 50px;
    font-size: 15px;
}
.rights-container{
    display: inline-block;
    text-align: left;
    width: 100%;
}
.rights-container .righst-card{
  border-radius: 8px;
}
.rights-container .right-column{
    display: inline-flex;
    flex-direction: column;
    padding-right: 80px;
    text-align: left;
    color: #565656;
    font-size: 19px;
    min-width: 140px;
    margin-bottom: 23px;
}   
.rights-container .right-column sub{
    color: #A5A5A5;
    font-size: 15px;
    position: unset;
    font-weight: 600;
    padding-bottom: 18px;
    min-width: max-content;
}
.rights-col{
    padding-right: 20px;
}
.rights-col .input-general{
    background: #fff !important;
    border-radius: 10px !important;
}
.rights-col .ant-picker {
    background-color: #fff;
    color: #919191cc !important;
    height: 40px;
    border: none;
    border-radius: 10px;
    width: 100%;
}
@media (max-width: 575px){
    .rights-box{
        padding-left: 35px;
        padding-right: 35px;
    }
}