.dsps .ant-card-body{
  padding-bottom: 40px;
}
.dsps .centered{
  text-align: center;
}
.dsps .question-icon{
  color: #b5b5b5;
  font-size: 15px;
  vertical-align: top;
  padding-left: 5px;
  cursor: pointer;
}
.dsps .ant-transfer {
    width: 100%;
    justify-content: space-evenly;
}
.dsps .ant-transfer-list {
    width: 45%;
    height: auto;
    padding: 10px 0;
    border-radius: 12px;
}
.dsp-content .ant-transfer-list-header-title{
  font-size: 15px;
}
.dsp-content .ant-transfer-list-header{
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.dsp-content .ant-transfer-list-content li.ant-transfer-list-content-item{
  padding: 8px 10px 8px 15px;
}
.dsp-content .ant-transfer-list-pagination{
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.dsp-content .ant-transfer-operation .ant-btn{
  border: none;
  border-radius: 4px;
}
.dsp-content .ant-transfer-operation .ant-btn .anticon{
  font-size: 15px;
}
.dsp-content .ant-btn-primary{
  width: 30px;
  height: 30px;
  margin: 7px 0;
  background-color: var(--outrageouse-orange);
}
.dsp-content .ant-btn-primary[disabled]{
  background-color: #d9d9d9;
}
/********* Medias /*********/
@media (max-width: 465px){
  .dsps .ant-card-body{
    padding-left: 8px;
    padding-right: 20px;
  }
  .dsp-content .ant-transfer-list-header{
    padding-left: 10px;
    padding-right: 10px;
  }
  .dsp-content .ant-transfer-list-pagination{
    padding-left: 0;
    padding-right: 0;
  }
  .dsp-content .ant-transfer-list-content li.ant-transfer-list-content-item{
    padding: 6px 12px;
  }
  .dsp-content .ant-btn-primary{
    width: 20px;
    height: 20px;
  }
  .dsp-content .ant-transfer-operation .ant-btn .anticon{
    font-size: 12px;
  }
}
