.requested-box{
  padding-left: 70px;
  padding-right: 70px;
}
.requested-box .title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  padding: 30px 0px 10px;
  justify-content: space-between;
}
.requested-box .list-requested{
  flex: 1 1 auto;
	padding-bottom: 5px;
}
.requested-box .ant-upload.ant-upload-select-picture-card{
  width: 95%;
  max-height: 48px;
  border-radius: 48px;
  border: 1px dashed #B1AFAF;
}
.requested-box .preview-track{
 	flex: 0 0 400px;
  background: #EDEDED;
}
.store-select-opts button{
  background-color: var(--outrageouse-orange);
  border: none;
  border-radius: 8px;
  width: auto;
  color: #fff;
  margin: 0 3px;
}
.store-select-opts button:hover,.store-select-opts button:active, .store-select-opts button:focus{
  background-color: var(--malibu);
  color: #fff;
}
.spin-request-store{
  text-align: center;
  width: 91%;
  height: 172px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #ffffff80;
  z-index: 1;
}
.spin-request-store .ant-spin-dot-item{
    background-color: var(--outrageouse-orange);
}
/**************** requested table ****************/
.requested-table{
    margin-top: 30px;
    padding-bottom: 0px;
    border-radius: 12px;
    background-color: #fff;
}
.requested-table table thead th{
    text-align: center;
    background: #E7E7E7;;
    text-transform: capitalize;
    position: sticky;
    font-weight: 500;
    text-align: left;
}
.requested-table .ant-table{
	background: transparent;
}
.requested-table .ant-table-tbody>tr.ant-table-row:hover>td, .requested-table .ant-table-tbody>tr>td.ant-table-cell-row-hover{
	background: rgba(187, 187, 187, 0.24);
}
.requested-table .ant-table-tbody>tr.ant-table-row:hover>td:first-child{
	background: rgba(187, 187, 187, 0.24);
	border-left: 3px solid var(--outrageouse-orange);
	padding-left: 13px;
}
.requested-table .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 12px;
  padding-left: 12px;
}
.requested-box .ant-table-thead th{
  font-weight: 600;
  background: #FAFAFA;
  padding-top: 10px;
  padding-bottom: 10px;
}
.requested-table table tr td{
  overflow-wrap: unset;
  width: 113px;
}
.requested-table table tr td:first-child{
  padding-left: 12px;
}
.requested-table table tr td:nth-child(2){
  width: 209px;
  min-width: 209px;
}
.plus-info{
    margin-left: 10px;
    font-size: 13px;
}
.requested-table .notRequested{
    color: #707070;
}
.requested-table .requested{
    color: #E3881D;
}
.requested-table .delivered{
    color: #00CF85;
}
.requested-table .deleted{
    color: #E3261A;
}
.requested-table .logo-dsp{
    height: 40px;
    padding-right: 20px;
}
.requested-table .dsp-btn{
    height: 30px;
    min-height: 30px;
    width: 150px;
    border-radius: 50px;
    text-transform: capitalize;
    color: #fff;
}
.dsp-btn.btn-notRequested, .dsp-btn.btn-deleted{
    background-color: #00CF85;
}
.dsp-btn.btn-requested{
    background-color: #4D4D4D;
}
.dsp-btn.btn-delivered{
    background-color: #fff;
    color: #565656;
}
.dsp-info .ant-popconfirm .ant-popover-buttons button{
    color: #7E7E8E;
    border-color: #7E7E8E;
    border-radius: 50px;
    background: transparent;
}
.dsp-info{
    padding: 20px;
}
.dsp-info .list-dsp-feats{
    list-style-type: none;
    column-count: 2;
    padding: 0;
}
.dsp-info .list-dsp-feats li{
    margin-bottom: 15px;
}
.dsp-info .list-dsp-feats svg{
    margin-right: 10px;
    vertical-align: middle;
}
.ant-popconfirm .ant-popover-buttons button.dsp-info-btn{
    color: #7E7E8E;
    background: transparent;
    border-radius: 50px;
    padding: 0 13px;
}
.requested-box .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled{
  opacity: 0.6;
}
.requested-box .action-reqs-btn{
  border-radius: 8px;
  border: none;
  background: linear-gradient(135deg, var(--outrageouse-orange), #ff4500, #ff7f50);
  color: #fff;
}
.requested-box .action-reqs-btn.ant-btn:focus, .requested-box .action-reqs-btn.ant-btn:hover{
  color: var(--white-text);
}
.requested-box .ant-tag{
  border-radius: 8px;
}
/**************** Medias ****************/
@media (max-width: 1044px){
	.requested-box{
		display: block;
	}
	.requested-box .list-requested{
	  width:100%;
	  min-width: unset;
	}
    .requested-table {
        padding-bottom: 0px;
        height: auto;
    }
    .requested-table table{
        min-width: 500px;
        overflow-y: auto;
    }
}
@media (max-width: 575px){
    .requested-box{
        padding-left: 35px;
        padding-right: 35px;
    }
}

