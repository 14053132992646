.release-resume h2, p{
	width: 100%;
}
.release-resume h2{
	overflow-wrap: break-word;
}
.admin-information .ant-modal-body{
	padding-top: 0;
}
.admin-information .release-resume h2{
    font-weight: 700;
    font-size: 22px;
    text-align:center;
    margin-bottom: 18px;
}
h2.copy-h2{
	padding-top: 20px;
}
.release-element-admin{
	font-size: 14px;
	display: block;
	padding-bottom: 16px;
}
.release-element-admin p{
	font-size: 17px;
	margin-bottom: 5px;
	font-weight: bold;
}
.regions-admin{
	width: 100%;
	border: 1px solid #f5f2f2;
	border-radius: 8px;
    padding: 10px;
    max-height: 222px;
    overflow-y: auto;
}
.release-resume img.flag{
	margin: 3px 5px;
}
.tracks-rel-admin, .tracks-rel-admin p{
	font-size: 12px;
	padding: 0;
}
.tracks-table-admin td{
	font-size: 12px;
}
.table-preview-tracks .ant-table-container{
    max-width: 100%;
    overflow-y: auto;
    max-height: 500px;
    overflow-x: auto;
}
.error-field.extra-pad{
	padding-bottom: 15px;
}
.titleReview{
    font-size: 15px;
    font-weight: 600;
    filter: brightness(1.2);
    color: var(--outrageouse-orange);
}
.titleToSend{
    font-size: 15px;
}
.goToReleases{
	background-color: var(--malibu);
}
.release-resume .preview-rel{
	padding-bottom: 0;
}
.release-resume .info-displayed h3{
	margin-bottom: 0;
	padding-bottom: 16px;
}
.release-resume .info-displayed .listed{
	font-size: 14px;
	padding-bottom: 16px;
}
.release-resume .info-displayed .listed b{
	font-size: 17px;
}