#release-reject-modal-actions .reject {
  background-color: #7e7e8e !important;
}

.overflowed {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 5px;
  padding-right: 5px;
}
.overflowed .ant-timeline-item-head-blue{
  color: var(--malibu);
  border-color: var(--malibu);
}