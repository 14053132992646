/*********** Modal ***********/
.modal-box, .modal-box .ant-modal-content{
    font-family: Geomanist;
    border-radius: 20px;
    max-width: 725px;
    width: unset !important;
    margin: auto;
    padding-bottom: 0;
}
.modal-box .ant-modal-content{
    background: #fff;
}
.modal-box .ant-modal-header{
    border: none;
    border-top-left-radius: 20px !important;    
    border-top-right-radius: 20px !important;
}
.modal-box .box-header{
    text-align: left;
    padding-top: 1em;
}
.modal-box .modal-title{
    font-size: 21px;
    font-weight: 400;
    padding: 0.3em 0;
}
.modal-title-artist{
    padding-bottom: 0;
    font-size: 25px;
    padding-top: 12px;
    font-weight: 600;
}
.less-pad{
    margin-top: 0 !important;
}
img.display-av{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.modal-box .modal-title-name{
    text-align: left;
    padding-top: 3em;
    font-size: 23px;
    font-weight: 800;
}
.modal-box p.default{
    text-align: center;
    font-weight: 600;
}
.modal-box p.messg{
    text-align: left;
    font-weight: 600;
    color: #75797E;
}
.modal-box .ant-modal-body{
    padding-top: 10px;
}
.modal-box .ant-modal-footer{
    border: none;
    padding-bottom: 2.5em;
    text-align: center;
}
.footer-twoBtn.cancel{
    background: #B3B9BF;
}
.footer-twoBtn.close{
    background: #FD6A88;
}
.modal-date{
    color: #B3B9BF;
    font-weight: 700;
    font-size: 13px;
    position: absolute;
    right: 3em;
    top: 8em;
}
.deleteModal{
  max-width: 550px;
}
.deleteModal .ant-modal-content{
  padding-top: 10px;
  border-radius: 20px;
}
.deleteModal .ant-modal-content .ant-modal-close{
  display: none;
}
.deleteModal .ant-modal-header{
  display: none;
}
.deleteModal .ant-modal-body{
  text-align: center;
  padding-top: 30px;
  padding-bottom: 8px;
  font-size: 15px;
}
.deleteModal .ant-modal-footer{
  border: none;
  text-align: center;
}
.modalTrackInfo .closeModalTrack{
  font-size: 22px;
  padding-top: 27px;
  padding-right: 10px;
}