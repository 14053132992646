.user-type{
  text-transform: capitalize;
}
.user-detls{
  padding-top: 28px;
  padding-bottom: 5px;
}
.avatar-admin.av-users img{
  width: auto;
}
.list-td-user{
  overflow: hidden;
  font-size: 12px;
  max-width: 130px;
  text-align: left;
  white-space: pre-wrap;
}
.ant-select-item[title="plan"]{
  text-transform: capitalize;
}
.users-title-table, .ant-table-cell.users-title-table{
  text-align: left;
  padding-left: 15px;
  padding-right: 10px;
  min-width: 120px;
}
.title-category.ps{
  text-align: center;
}
.content-table-admin.users td.title-category{
  padding-right: 12px;
  /* padding-left: 12px; */
}
.content-table-admin.users td.title-category.album .list-td-user{
  text-align: left;
}
.content-table-admin.users td.title-category.album .list-td-user .seemore-btn{
  color: var(--outrageouse-orange);
}
.content-table-admin.users td.title-category.album .list-td-user .seemore-btn::first-letter {
  text-transform: capitalize;
}
.content-user.ant-row-middle{
  align-items: normal;
}
.user-div .label{
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 8px;
}
#user-detail-container .edit_button.usr[disabled]{
  background-color: #f8f8f8;
  stroke-opacity: 0.3;
}
.phone-div .relative-input{
  min-height: 30px;
  height: 30px;
}
.av-users .payee-check{
  width: 15px;
  height: 15px;
}
.av-users .payee-cross{
  width: 22px;
  height: 22px;
}
.add-user{
  margin-bottom: 20px;
  margin-right: 0px;
}
.tag-step.active{
  background-color: #d9ead3;
  color: #2e8b57;
}
.tag-step.unpaid{
  background-color: #fff2cc;
  color: #7b5c00;
}
.tag-step.disabled{
  background-color: #f4cccc;
  color: #8b0000;
}
.tag-step.invited{
  background-color: #d3d9ff;
  color: #465198;
}
.tag-step.expired{
  background-color:#fce5cd;
  color: #b35900;
}
/************ Media ************/
@media (max-width: 550px) {
  .add-user{
    width: 100%;
    min-width: 100%;
  }
}