.thanks-verify{
    background-color: var(--color-container);
    border-radius: 10px;
    margin: 80px 20px;
}
.verifymail{
    padding: 0 20px;
    max-width: 850px;
    margin: auto;
    text-align: left;
    height: 620px;
}
.thanks-verify h1{
    font-size: 45px;
    padding: 0;
    margin-bottom: 25px;
}
.thanks-verify h3{
    font-size: 22px;
    padding: 0;
    margin-bottom: 17px;
}
.verifymail-body{
    font-size: 16px;
    max-width: 400px;
    text-align: justify;
}
.verifymail-body strong{
    font-size: 19px;
    font-weight: 800;
    padding-left: 5px;
    cursor: pointer;
    color: var(--outrageouse-orange);
}
.verifymail-icon{
    display: block;
    text-align: right;
    position: relative;
    bottom: 90px;
    left: 35px;
}
.verifymail-icon img{
    height: 441px;
    width: auto;
}
.goHome-btn{
    position: relative;
    z-index: 1;
}
@media (max-width: 933px){
    .verifymail{
        height: auto;
    }
    .thanks-verify h1{
        font-size: 35px;
    }
    .verifymail-icon{
        display: block;
        text-align: left;
        margin: auto;
        position: unset;
        bottom: unset;
        left: unset;
    }
    .verifymail-icon img{
        height: 100%;
        width: 100%;
    }
}