.container-box .sign-box{
    border: 1px solid #DADCE0;
	background-color: #fff;
    border-radius: 10px;
    padding: 50px 50px;
    max-width: 500px;
    margin: 30px auto;
    text-align: center;
    box-shadow: var(--shadow-elevation-high);
}
@media (max-width: 530px) {
    .container-box .sign-box{
        padding: 30px 20px;
        margin-top: 50px;
        margin-bottom: 80px;
    }
}
.sign-box .ant-divider-plain.ant-divider-with-text{
    margin-top: 5px;
}
.sign-box div{
    position: relative;
}
.sign-box .ant-divider-horizontal.ant-divider-with-text::before, .sign-box .ant-divider-horizontal.ant-divider-with-text::after{
    border-top: 2px solid inherit !important;
}
.sign-box .text-error{
    top: -11px;
    text-align: left;
}
.social-btns{
    display: flex;
    justify-content: space-between;
}
.social-btns .fb{
    padding-right: 5px;
}
.social-btns .gl{
    padding-left: 5px;
}
.p-1{
    padding-top: 60px;
    margin-bottom: 0;
}