.container-admin{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    width: 100%;
    padding: 20px 60px 50px 60px;
    max-width: 1380px;
}
.general-card-admin .ant-table{
    overflow-x: auto;
}
.table-admin.inline-contend{
    display: block;
    flex-wrap: unset;
    border-radius: 0px;
    min-width: 540px;
}
.table-admin{
    padding: 0;
}
.table-admin .ant-table-content{
    background: #f4f4f4;
    table-layout: auto;
}
.table-admin.inline-contend .ant-table-pagination.ant-pagination{
    margin-bottom: 30px;
}
.avatar-admin img {
    height: 50px;
    width: 50px;
    max-width: 50px;
    border-radius: 5px;
}
.table-admin .ant-table-tbody>tr.ant-table-row:hover>td, .table-admin .ant-table-tbody>tr>td.ant-table-cell-row-hover{
    background-color: #ededed;
}
.table-admin .ant-table-tbody>tr>td:first-child.ant-table-cell-row-hover{
    border-left: 3px solid var(--outrageouse-orange);
}
.table-admin .ant-table-tbody>tr.ant-table-placeholder:hover>td{
    background-color: #f4f4f4;
    border: none;
}
.table-admin table tr td{
    padding: 13px 0;
    font-size: 13px;
    background-color: #fff;
    /* border-bottom: 1px solid #E9E9E9; */
}
.avatar-admin:hover .menu-cd{
    opacity: 1;
    visibility: visible;
}
.table-admin .menu-cd{
    width: 50px;
    height: 50px;
    margin: 9px auto;
    text-align: center;
    right: 0;
    left: 0;
}
.table-admin .id-category {
    text-align: center;
}
.table-admin .title-category{
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1px 3px;
}
.table-admin .title-category.album{
    max-width: 150px;
    width: 150px;
}
.table-admin td.title-category{
    min-height: 69px;
}
.table-admin .new-artist .idcategory{
    width: 60px;
    text-align: center;
    padding: 14px 0;
}
.table-admin .new-artist .category{
    padding: 14px 0;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.table-admin .ant-empty-normal{
    width: 100%;
}
.table-admin .menu-cd svg{
    font-size: 15px;
}
.delete-detail{
    color: #f00000;
    margin-right: 10px;
    margin-left: 10px;
}
.edit-detail{
    color:#1890ff;;
}
.tables .top-actions-srch{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.tables .searchers{
    display: inline-flex;
    justify-content: left;
    flex-wrap: wrap;
    padding-bottom: 0px;
}
.searchers .relative-input{
    padding-bottom: 15px;
    min-width: 176px;
    margin-right: 16px;
    display: inline-block;
    vertical-align: top;
}
.tables .searchers .relative-input .search-sec .ant-select-selection-item{
    padding-top: 0px !important;
}
.tables .bar-filters{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding-bottom: 20px;
}
.bar-filters .relative-input{
    min-width: 176px;
    margin-right: 16px;
}
.tables .searchers h1{
    font-size: 35px;
    font-weight: 700;
    margin: 0;
    padding-right: 30px;
}
.search-sec{
    max-width: 176px;
    text-align: left;
    margin-right: 15px;
    text-transform: capitalize;
}
.search-sec .ant-select-item-option-content{
    text-transform: capitalize;
}
.search-sec .ant-select-selector{
    height: 48px !important;
    padding: 9px 11px !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
}
.search-sec.selector-general .ant-select-arrow {
    top: 24px;
    font-size: 15px;
}
.view-details-rel{
    cursor: pointer;
    color: var(--outrageouse-orange);
    padding: 7px 5px;
    border-radius: 48px;
    text-align: center;
    margin: 1px 7px;
    font-size: 17px;    
}
.view-edit-rel{
    font-size: 17px;
}
.view-fingerprint-rel{
    color: #fff;
    padding: 7px 0;
    text-align: center;
    width: 100%;
    max-width: 180px;
    display: block;
    background: #f49700;
    margin-top: 7px;
    border-radius: 8px;
    font-weight: 800;
}
.view-fingerprint-rel .warn{
    font-size: 11px;
    margin-right: 5px;
    vertical-align: text-top;
}
.view-fingerprint-rel:hover{
    filter: brightness(0.95);
    color: #fff;
}
.req-by {
    margin: auto; 
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.req-by div{
    font-size: 11px;
    text-transform: uppercase;
}
.header-tab-ad{
    background: #E7E7E7;
    text-transform: uppercase;
    border-top: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    height: 56px;
    left: 0;
    position: absolute;
}
.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
    background-color: transparent;
}
.searchers .searcher.sec .ant-input-group-addon .ant-input-search-button{
    position: absolute;
    top: 8px;
    border: none;
    background: transparent;
    z-index: 1;
    width: 30px;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    right: 9px;
}
.tables .searchers .searcher.sec{
    border-radius: 8px !important;
    padding-right: 0;
    background: #fff !important;
    margin-top: 0;
    padding-top: 0 !important;
    height: 48px;
    margin-bottom: 0px;
}
.searchers .searcher.sec .ant-input{
    padding: 9px 10px;
    min-height: 45px;
    border-radius: 8px !important;
    font-size: 15px;
    padding-right: 35px;
    width: 180px;
}
.searcher.back input{
    background: #fff !important;
}
.searchers .searcher.sec .ant-input-group-addon{
    background-color: unset;
}
.general-card-admin .table-admin .seemore{
    cursor: pointer;
    color: #8d8d8d;
    font-size: 15px;
}
.general-card-admin .table-admin .seemore:hover{
    color:  #c3c3c3;
}
.searchers .search-sec .ant-select-arrow{
    padding-right: 10px;
}
.admin-information{
    max-width: 700px;
    width: 100% !important;
    font-family: Geomanist;
    border-radius: 20px;
}
.admin-information .ant-modal-content{
    border-radius: 10px;
    padding-top: 20px;
}
.admin-information .ant-modal-close-x{
    display: none;
}
.admin-information .ant-modal-header{
    border-bottom: 0;
}
.admin-information .modal-title{
    font-weight: bold;
    font-size: 25px;
}
.admin-information .release-element span{
    font-weight: bold;
    font-size: 14px;
}
.admin-information .field{
    width: 100%;
    padding-bottom: 10px;
}
.content-table-admin table thead th{
    color: #3d3d3d;
    text-align: center;
    background: #fff;
    text-transform: uppercase;
    font-size: 14px;
    position: sticky;
    top: 0;
    font-weight: 900;
    z-index: 1;
}
.content-table-admin .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before{
    background-color: unset;
    content: none;
}
.tracks-admin{
    padding-top: 13px;
}
.tracks-table-admin{
    width: 100%;
    border: 1px solid #f5f2f2;
    border-radius: 8px;
    overflow-x: auto;
}
.tracks-table-admin .ant-table-thead>tr>th{
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
}
.approve-rel-admin{
    display: inline-block;
    float: left;
}
.approve-rel-admin .general-btn{
    min-width: 96px;
}
.approve-rel-admin .appr{
    background-color: var(--malibu-orig);
}
.approve-rel-admin .reject{
    background-color: #7E7E8F;
}
.date-range-admn{
    border: none !important;
    height: 28px;
    max-width: 230px;
    padding: 4px 11px;
    min-height: unset;
    border-radius: 8px !important;
    background-color: #ffffff !important;
}
.date-range-admn .ant-picker-input>input::placeholder, .date-range-admn .ant-picker-input>input{
    color: #898686;
    text-transform: capitalize;
}
.dateField{
    display: inline-block;
    flex-direction: column;
    font-size: 12px;
    background: #fff;
    border-radius: 8px;
    padding-top: 4px;
    height: 48px;
    color: #898686;
    margin-right: 15px;
    margin-bottom: 30px;
    border: 1px solid #dadce0;
}
.dateField .label{
    padding-left: 10px;
    height: 14px;
}
.dateField .ant-picker-focused, .dateField .ant-picker:hover{
    border-color: unset !important;
    border: 0px;
    box-shadow: unset;
}
.search-sec.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: none;
    /* box-shadow: 0 0 0 2px rgba(244, 92, 46, 0.5); */
}
.list-dsp{
    overflow: hidden;
    text-transform: uppercase;
    font-size: 12px;
    max-width: 130px;
    text-align: left;
    white-space: pre-wrap;
}
.title-category .title-rel{
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.title-category.contract-tb{
  padding-top: 16px;
  padding-bottom: 16px;
}
.title-category.tb-left{
  padding-left: 20px;
}
.seemore-btn{
    display: inline-block;
    color: var(--outrageouse-orange);
    text-transform: capitalize;
}
.seemore-btn:hover{
    cursor: pointer;
    filter: brightness(1.5);
}
.isrc-titl{
  font-size: 22px;
  font-weight: 500 !important;
  padding-bottom: 10px;
}
.assign-irsc-btn, .assign-irsc-btn:hover, .assign-irsc-btn:focus{
  width: 100%;
  max-width: 150px;
  height: 40px;
  border-radius: 15px;
  border: none;
  font-weight: 800;
  vertical-align: sub;
  margin-top: 12px;
  background-color: var(--outrageouse-orange);
}
.mb-0 .assigned{
  font-size: 17px;
  font-weight: 200;
  background: #f8f8f8;
  width: max-content;
  padding: 8px 12px;
  border-radius: 8px;
}
.ant-popconfirm .ant-popover-inner{
    border-radius: 8px;
    position: relative;
}
.ant-popconfirm .ant-popover-inner-content{
    min-width: 190px;
    max-width: 205px;
    overflow-y: auto;
    padding: 14px 14px 10px;
}
.ant-popconfirm .ant-popover-message{
    padding-bottom: 0;
    /* max-height: 250px; */
    margin-bottom: 45px;
    overflow-y: auto;
}
.ant-popconfirm .ant-popover-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: #ffffff;
    padding: 8px;
    margin-bottom: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.ant-popconfirm .ant-popover-buttons button.ant-btn-default{
  border: none;
  background: unset;
}
.ant-popconfirm .ant-popover-buttons button{
    border-color: unset;
    background: var(--outrageouse-orange);
    border-radius: 8px;
    height: 28px;
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
}
.view-stores{
    padding-left: 0px;
    list-style: none;
    max-width: 290px;
}
.view-stores li{
    border: 1px solid #ff5f2d78;
    padding: 3px 9px;
    margin-right: 5px;
    margin-bottom: 6px;
    border-radius: 12px;
    font-size: 11px;
    text-align: center;
    display: inline-block;
}
.view-stores li::marker{
    padding-right: 5px;
}
.action-btn .general-btn{
    min-height: unset;
    height: 35px;
    display: block;
    border-radius: 8px;
    min-width: 180px;
    font-size: 13px;
    font-weight: 800;
    padding-left: 0;
    padding-right: 0;
    text-transform: capitalize;

    margin: 0;
    margin-top: 7px;
    margin-bottom: 7px;
 
}
.action-review{
    color: #abababd9;
    font-size: 13px;
}
.action-btn .grn{
    background-color: #00CF85;
    background: #00CF85;
    box-shadow: none;
}

.action-btn .grn:hover, .action-btn .grn:focus, .action-btn .grn:active{
    background-color: #00bd79;
}
.action-btn .rd{
    background-color: rgb(255 59 0 / 15%);
    background: rgb(255 59 0 / 15%);
    color: #F45C2E;
    box-shadow: none;
}

.action-btn .rd:hover, .action-btn .rd:focus, .action-btn .rd:active{
    background-color: rgb(255 59 0 / 25%);
    background: rgb(255 59 0 / 25%);
}

.priority-btn{
    background: #1EA934;
    border-radius: 8px;
    height: 30px;
    min-height: unset;
    width: 90px;
    text-transform: capitalize;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: #b2b2b2;
    font-weight: 700;
}
.priority-btn:hover{
    filter: brightness(1.1);
}
.priority-btn.low, .item-priority.low::before, .priority-btn.active{
    background: #04cf85;
}
.priority-btn.medium, .item-priority.med::before{
    background: #ffb700;
}
.priority-btn.high, .item-priority.high::before{
    background: #43a6e5;
}
.drop-priorities li:hover{
    background-color: #f5f5f5;
}
.item-priority::before {
    display: inline-block;
    width: 8px;
    background-color: #b2b2b2;
    content: " ";
    height: 8px;
    border-radius: 50px;
    margin-right: 7px;
}
.table-admin .status-rel{
  text-align: center;
}
.tag-step{
  border-radius: 50px;
  height: 25px;
  min-height: unset;
  width: 90px;
  text-transform: capitalize;
  border: none;
  font-weight: 500;
  padding: 3px 12px;
  max-width: 100%;
  width: max-content;
  margin: auto;
}
.tag-step.draft, .tag-step.draftclient {
    background-color: #ded2e9;
    color: #4b0082;
}

.tag-step.readyToSent {
    background-color: #fff2cc;
    color: #7b5c00;
}

.tag-step.pendingDistribution, .tag-step.pendingDistributionclient {
    background-color: #fce5cd;
    color: #b35900;
}

.tag-step.readyToSentclient, .tag-step.inReview {
    background-color: #d0e0e3;
    color: #34565c;
}

.tag-step.inReviewclient{
    background-color: #eef7cf;
    color: #4c6c00;
}

.tag-step.rejected, .tag-step.rejectedclient {
    background-color: #f4cccc;
    color: #8b0000;
}

.tag-step.delivered, .tag-step.deliveredclient {
    background-color: #c9daf8;
    color: #003366;
}

.tag-step.published, .tag-step.publishedclient {
    background-color: #d9ead3;
    color: #2e8b57;
}
/********* Media *********/
@media (max-width: 991px) and (min-width: 575px){
    .isrc-titl-act{
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 20px;
    }
    .assign-irsc-btn, .assign-irsc-btn:hover, .assign-irsc-btn:focus{
        vertical-align: sub;
        margin-top: 0;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: unset;
        max-width: 150px;
    }
}    
@media (max-width: 575px){
    .assign-irsc-btn, .assign-irsc-btn:hover, .assign-irsc-btn:focus{
        vertical-align: sub;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 80%;
    }
}
@media (max-width: 431px){
    .approve-rel-admin{
        float: unset;
        width: 100%;
        text-align: center;
    }
    .approve-rel-admin .general-btn{
        min-width: 48%;
    }
    .modalAdd-btns .close{
        min-width: 100%;
    }
}
@media (max-width: 465px){
    .bar-filters .relative-input, .search-sec{
        min-width: 100%;
        max-width: 100%;
    }
    .dateField{
        width: 100%;
        margin-right: 0;
    }
    .date-range-admn{
        max-width: 100%;
        width: 100%;
    }
}
@media (max-width: 785px){
    .tables .searchers .searcher.sec,.searchers .responsive{
        width: 100%;
        padding-bottom: 30px;
    }
    .searchers .relative-input, .tables .dateField{
        width: 100%;
        margin-right: 0;
    }
    .search-sec{
        max-width: 100%;
    }
    .top-actions-srch a.new-release{
       text-align: right;
       display: contents;
    }
}
@media (max-width: 884px){
    .table-admin.inline-contend{
        min-width: unset;
    }
}