#user-detail-container .user-div .content-user-det{
  justify-content: left;
}
#user-detail-container .edit_button, .tooltp-func button[disabled] {
  width: 40px;
  height: 40px;
  padding: 10px;
  border: none;
  background: #f6f6f6;
  padding: 10px;
}
#user-detail-container .tooltp-func.line {
  position: inherit;
}
.tooltp-func button[disabled] {
  opacity: 0.5;
}
#user-detail-container .tooltp-func{
  background: unset;
  padding: 4px;
}
#user-detail-container .active {
  background-color: rgba(244, 92, 46, 0.15) !important;
}
#user-detail-container .edit_button{
  position: absolute;
  right: 30px;
}
#user-detail-container .edit_button svg {
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
}
#user-detail-container .edit_button:hover svg,
#user-detail-container .edit_button:focus svg {
  opacity: 1;
}
button.edit_button::after{
  border: none !important;
  opacity: 0;
  box-shadow: none !important;
}
.actions-det {
  min-width: 88px;
  width: 88px;
  text-align: center !important;
}
.actions-icons{
  display: flex;
  justify-content: space-around;
}
.actions-icons.hgt{
  height: 18px;
}
.disable-detail {
  color: #f28f8f;
  margin-top: 2px;
}
.active-detail {
  color: #7dd492;
  cursor: pointer;
}
.resend-icon, .cancelinvite-icon{
  height: 19px;
  width: 19px;
  cursor: pointer;
}
.resend-icon g{
  fill: #f0b503;
}
.cancelinvite-icon g{
  fill: #ff813c;
}
/********** User Detail **********/
.general_information.user-detls .ant-card{
  min-height: 290px;
}
.user-detls .label label{
  display: block;
  padding-bottom: 5px;
}
.user-div .label p{
  color: #000;
  font-size: 16px;
}
.pwd-confirm .ant-modal-confirm-btns button:nth-child(1){
  display: none;
}
.pwd-confirm .ant-modal-confirm-btns button.ant-btn-primary{
  background: var(--outrageouse-orange);
  border: none;
  color: #fff;
  border-radius: 7px;
}
.pwd-confirm-icon{
  color:#3baa3b !important;
}
.pwd-confirm .ant-modal-content{
  border-radius: 20px;
}
.pwd-confirm .newPwd{
  margin-top: 10px;
  color:#3baa3b;
  font-weight: 600;
}
.row-flex-btwn{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.users-artistlist .ant-table{
  max-height: 700px;
  overflow-y: auto;
}
/********** Contract **********/
.content-user .mayus{
  text-transform: uppercase;
}