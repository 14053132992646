.thanks-box{
    border-radius: 10px;
    margin: 0px 20px 20px;
}
.greeting{
    padding: 0 20px;
    max-width: 850px;
    margin: auto;
    text-align: left;
    padding-top: 40px;
}
.thanks-box h1{
    font-size: 49px;
    padding: 0;
    margin-bottom: 25px;
}
.thanks-box h3{
    font-size: 22px;
    padding: 0;
    margin-bottom: 25px;
}
.greeting-body{
    font-size: 19px;
    margin-bottom: 25px;
    max-width: 400px;
    text-align: justify;
}
.greeting-body p{
    font-size: 24px;
    font-weight: 800;
    color: var(--outrageouse-orange);
}
.greet-icon{
    display: block;
    text-align: right;
    position: relative;
    bottom: 26px;
    left: 35px;
}
.greet-icon svg{
    height: 300px;
    width: 420px;
}
.goHome-btn{
    position: relative;
    z-index: 1;
}
@media (max-width: 933px){
    .greeting{
        height: auto;
    }
    .greet-icon{
        display: block;
        text-align: left;
        margin: auto;
        position: unset;
        bottom: unset;
        left: unset;
    }
    .greet-icon svg{
        height: 300px;
        width: 100%;
    }
}
@media (max-width: 530px){
    .greeting{
        margin: 10px 0 50px;
    }
    .thanks-box h1{
        font-size: 30px;
    }
    .thanks-box h3{
        font-size: 20px;
    }
    .greeting-body{
        font-size: 17px;
    }
    .greeting-body p{
        font-size: 22px;
    }
}