.card-mask {
  position: relative;
}

.card-mask-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  background-color: rgba(100, 100, 100, 0.1); /* Semi-transparent black */
  z-index: 3;
}

.card-mask-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}
