.actions-box{
	padding: 70px;
}
.actions-release{
	padding-bottom: 40px;
	text-align: right;
    display: flex;
    justify-content: flex-end;
}
.actions-release .general-btn{
	margin-left: 30px;
	margin-right: 0px;
	text-transform: capitalize;
	border-radius: 48px;
	min-width: 170px;
	min-height: 45px;
	font-size: 16px;
    white-space: pre-wrap;
    height: auto;
}
.actions-release .in-review-text {
    margin-left: 30px;
    margin-right: 0px;
    text-transform: capitalize;
    min-width: 142px;
    height: 65px;
    font-size: 16px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #abababd9;
}
/**************** Medias ****************/
@media (max-width: 760px){
    .actions-release{
        width: 100%;
        display: inline-block;
        text-align: center;
    }
    .actions-release .general-btn.grn{
        width: 100%;
        margin-left: 0px;
    }
    .actions-release .in-review-text{
        text-align: center;
    }
}
@media (max-width: 575px){
    .actions-box{
        padding-left: 35px;
        padding-right: 35px;
    }
}