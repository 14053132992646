.verify-box .title {
  width: 100%;
  text-align: left;
  padding: 50px 0px 20px;
}
.verify-box h1 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  display: inline-block;
  margin-right: 40px;
}
.row-verif{
  margin-bottom: 25px;
  margin-left: 10px;
}
.verify-box {
  background: #fff;
  padding: 30px 40px;
  min-width: 367px;
  max-width: 100%;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  box-shadow: var(--shadow-elevation-high);
}
.row-preview-verif {
  padding: 15px 0;
  display: flex;
}
.verify-box .sub {
  color: #a5a5a5;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.verify-box .icon-head {
  display: inline-block;
  width: 44px;
  height: 44px;
  background: rgba(244, 92, 46, 0.15);
  border-radius: 50px;
  padding: 12px;
  margin-right: 20px;
  vertical-align: top;
}
.verify-box .names {
  font-size: 16px;
  color: #565656;
  font-weight: 500;
  width: 100%;
  max-width: 230px;
}
.album-info{
  display: flex;
  justify-content: left;
  margin-bottom: 12px;
}
.album-info .info-titl{
  font-size: 17px;
  margin-bottom: .5em;
  line-height: 1.4;
  padding-left: 15px;
}
.verify-box .album-desc{
  font-size: 15px;
  color: #828282;
  display: block;
  padding-bottom: 25px;
}
.verify-box .first-panel {
  border-top: 1px solid #d9d9d9;
}
.verify-box .ant-collapse-header-text{
  font-size: 15px;
  color: #828282;
}
.verify-box.ant-collapse>.ant-collapse-item>.ant-collapse-header:hover .ant-collapse-header-text,.verify-box.ant-collapse>.ant-collapse-item>.ant-collapse-header:hover .ant-collapse-arrow{
  color: var(--outrageouse-orange);
}
.verify-box.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
  color: #828282;
}
.metadata-title{
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
}
.external-links-title{
  margin-left: 10px;
}
.external-links{
  background: #fbfbfb;
  border-radius: 7px;
  margin-left: 10px;
}
.external-links .ant-list-item-meta-description a{
  color: var(--outrageouse-orange);
}
.verify-btns{
  display: flex;
  justify-content: right;
  margin-top: 50px;
}
.verify-btns .general-btn{
  margin-left: 5px;
}
.verify-btns .reject{
  background: #8f8f98;
  box-shadow: 0 4px 14px 0 rgb(178 175 174 / 39%);
}
.verify-btns .close{
  background: none;
  box-shadow: none;
  color: #565656;
  font-size: 14px !important;
}
.verify-btns .close span{
  font-size: 14px !important;
}
.success-load{
  display: block;
  margin: 20px auto 10px;
  font-size: 20px;
  color: var(--success);
}
.success-top{
  color: var(--success);
}
.no-data-found{
  width: 100%;
  color: #c3c3c3;
  font-style: italic;
}
.no-data-found .icon-head{
  background: rgb(218 214 213 / 15%);
}
.no-data-found .names{
  color: #c3c3c3;
}
/**************** Medias ****************/
@media (max-width: 1044px) {
  .verify-box,
  .verify-box .tracker-container {
    display: block;
  }
  .verify-box .list-tracks {
    width: 100%;
    min-width: unset;
  }
  .content-table-tracks {
    padding-bottom: 0px;
    height: auto;
  }
.verify-box .names,
.verify-box {
    max-width: 100%;
  }
.verify-box {
    max-height: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 875px) {
  .verify-btns {
    flex-direction: column;
  }
}
@media (max-width: 810px) {
  .verify-box, .external-links.ant-list-sm .ant-list-item {
    overflow-x: auto;
  }
}
@media (max-width: 575px) {
  .verify-box {
    padding-left: 35px;
    padding-right: 35px;
  }
}