.tracks-box {
  padding-left: 70px;
  padding-right: 70px;
}
.tracks-box .title {
  width: 100%;
  text-align: left;
  padding: 50px 0px 20px;
}
.tracks-box h1 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  display: inline-block;
  margin-right: 40px;
}
.tracks-box .tracker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tracks-box .mark-icon {
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #fff;
  padding: 10px;
  border-radius: 50px;
  font-size: 15px;
}
.tracks-box .list-tracks {
  flex: 1 1 auto;
  min-width: 0;
  background: #ededed;
  padding: 40px 2px 35px 30px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.tracks-box .ant-upload.ant-upload-select-picture-card {
  width: 95%;
  max-height: 48px;
  border-radius: 48px;
  border: 1px dashed #b1afaf;
}
.tracks-box .preview-track {
  flex: 0 0 400px;
  background: #ededed;
}
.sideBtn-drop .text-drop {
  margin-left: 12px;
  color: #8c8c8c;
  display: inline-block;
  height: 26px;
  vertical-align: sub;
}
.sideBtn-drop svg {
  display: inline-block;
  background: #b1afaf;
  color: #565656;
  width: 26px;
  height: 26px;
  padding: 6px;
  border-radius: 7px;
  vertical-align: middle;
}
.desc-drop {
  color: #8c8c8c;
  font-size: 10px;
  display: block;
}
/**************** Tracks table ****************/
.content-table-tracks {
  max-width: 2560px;
  margin: 30px auto;
  padding-bottom: 30px;
  height: 442px;
  overflow: auto;
  overflow-y: scroll;
  border-right: 1px solid #dfdfdf;
  padding-right: 20px;
  border-radius: 2px;
}
.content-table-tracks table thead th {
  text-align: center;
  background: transparent;
  text-transform: capitalize;
  position: sticky;
  font-weight: 500;
  text-align: left;
}
.content-table-tracks table thead th:nth-child(3) {
  color: #a5a5a5;
}
.content-table-tracks .ant-table {
  background: #fff;
}
.content-table-tracks .ant-table-tbody > tr.ant-table-row:hover > td,
.content-table-tracks .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: rgba(187, 187, 187, 0.24);
}
.content-table-tracks
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  background: rgba(187, 187, 187, 0.24);
  border-left: 3px solid var(--outrageouse-orange);
  padding-left: 13px;
}
.content-table-tracks .ant-table-tbody > tr > td {
  border-bottom: 1px solid #d3d3d3;
}
.icon-play-track {
  font-size: 35px;
  cursor: pointer;
  color: var(--outrageouse-orange);
}
.icon-pause-track {
  font-size: 35px;
  cursor: pointer;
  color: var(--outrageouse-orange);
}
.icon-eye-track {
  font-size: 35px;
  height: 35px;
  width: 35px;
  margin-left: 10px;
}
.icon-eye-track svg {
  background: #e5e5e5;
  border-radius: 50px;
  padding: 8px;
}
.icon-trash-track {
  width: 35px;
  height: 35px;
  background: #ffcac7;
  padding: 8px;
  border-radius: 50px;
  margin-left: 10px;
}
/**************** Track selected ****************/
.trackDetail {
  background: #ededed;
  padding: 33.2px 25px;
  text-align: left;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.trackDetail h3 {
  font-weight: 700;
  font-size: 20px;
}
.trackDetail .track-box {
  background: #fff;
  border-radius: 12px;
  padding: 30px 40px;
  min-width: 367px;
  min-height: 540px;
  max-height: 100%;
  max-width: 375px;
  position: relative;
}
.trackDetail .track-box .row-preview {
  padding: 20px 0;
  display: flex;
}
.trackDetail .track-box .sub {
  color: #a5a5a5;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.trackDetail .track-box .icon-head {
  display: inline-block;
  width: 44px;
  height: 44px;
  background: rgba(244, 92, 46, 0.15);
  border-radius: 50px;
  padding: 12px;
  margin-right: 20px;
  vertical-align: top;
}
.trackDetail .track-box .info-head {
  display: inline-block;
  text-wrap: wrap;
  width: 85%;
}
.trackDetail .track-box .names {
  font-size: 16px;
  color: #565656;
  font-weight: 500;
  width: 100%;
  max-width: 230px;
}
.track-publisher,
.track-publisher p {
  font-size: 16px;
  padding: 0;
}
.copy-artist-btn {
  border-radius: 50px;
  text-transform: capitalize;
  width: 100%;
  height: auto;
  text-wrap: wrap;
}
.mark-icon-edit {
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #f6f6f6;
  padding: 10px;
  border-radius: 50px;
  font-size: 15px;
  position: absolute;
  right: 12px;
  top: 12px;
}
.mark-icon-edit .anticon {
  font-size: 20px;
}
.actions-tracks {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
/**************** Modal Track ****************/
.modalTrackInfo.modal-box,
.modalTrackInfo.modal-box .ant-modal-content {
  max-width: 93%;
}

.general-info-tracks .input-general,
.general-info-tracks .selector-general .ant-select-selector,
.general-info-tracks .ant-picker {
  margin-bottom: unset !important;
}

.general-info-tracks .ant-select-disabled,
.general-info-tracks .ant-select,
.general-info-tracks .ant-picker,
.general-info-tracks .ant-picker-disabled {
  width: 100%;
}

.track-detail-form .uploadaudio-line {
  display: flex;
  padding-bottom: 50px;
}
.track-detail-form .uploadaudio-line .ant-upload-picture-card-wrapper {
  width: auto;
}
.replace-media-col {
  align-content: end;
  text-align: center;
}
.track-media-uploader .ant-upload.ant-upload-select-picture-card {
  background: var(--outrageouse-orange);
  color: #fff;
  height: 40px;
  border-radius: 15px;
  width: 175px;
  font-weight: 600;
  border: none;
}
.track-media-uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  color: #fff;
}
.track-media-uploader .upload-icon {
  margin-right: 10px;
}
.isrc-col {
  display: flex;
}
.isrc-col button {
  display: inline-flex;
  margin: 0;
  border-radius: 50px;
  text-transform: capitalize;
  min-width: 98px;
  padding: 9px 10px;
  text-align: center;
}
.artist-contributors {
  background: #ededed;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}
.artist-contributors-desc {
  color: #a5a5a5;
  font-size: 13px;
}
.copyArtist-btn {
  border-radius: 50px;
  text-transform: capitalize;
  margin-top: 25px;
}
.artist-contributors .col-contrib {
  padding-right: 15px;
}
.artist-contributors .col-contrib-right {
  padding-left: 15px;
}
.row-contribs{
  display: contents;
}
.row-preview-track {
  display: inline-flex;
  margin-bottom: 20px;
}
.row-preview-track .role {
  vertical-align: bottom;
}
.row-preview-track .icon-head {
  display: inline-block;
  width: 44px;
  height: 44px;
  background: rgba(244, 92, 46, 0.15);
  border-radius: 50px;
  padding: 12px;
  margin-right: 20px;
  vertical-align: top;
}
.row-preview-track .icon-head svg {
  color: var(--outrageouse-orange);
  font-size: 20px;
}
.row-preview-track .info-head {
  margin: auto 0;
}
.col-contrib .title-roles {
  margin-top: 30px;
}
.roles-cards {
  border-radius: 8px;
  margin-bottom: 35px;
}
.select-role.uniselect .ant-select-selection-item {
  padding-top: 5px;
}
.row-track-input.left {
  padding-right: 12px;
}
.row-track-input.cross {
  margin-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  cursor: pointer;
  position: relative;
  top: 0;
  right: 10px;
}
.roles-cards .btn-submit {
  padding-top: 15px;
  text-align: right;
}
.roles-cards .btn-submit svg {
  vertical-align: middle;
  margin-right: 8px;
}
.new-artist-add.track-edit-btn {
  border-radius: 48px;
  border: 1px solid #7e7e8e;
  color: #7e7e8e;
  padding: 0 10px;
}
.row-preview-track.name-icon,
.row-preview-track.role-icon {
  display: none;
}
.row-track-input.right {
  padding-bottom: 10px;
}
button.track-edit-btn:disabled,
.disabled-cross {
  background: #f5f5f5;
  filter: opacity(0.5);
  cursor: no-drop;
}
.content-table-tracks.tracks-table .ant-table-content {
  overflow-x: auto;
}
/**************** Medias ****************/
@media (max-width: 1044px) {
  .tracks-box,
  .tracks-box .tracker-container {
    display: block;
  }
  .tracks-box .list-tracks {
    width: 100%;
    min-width: unset;
  }
  .content-table-tracks {
    padding-bottom: 0px;
    height: auto;
  }
  .trackDetail .track-box .names,
  .trackDetail .track-box {
    max-width: 100%;
  }
  .content-table-tracks.tracks-table table {
    min-width: 500px;
    overflow-y: auto;
  }
  .trackDetail .track-box {
    max-height: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 700px) {
  .track-detail-form .uploadaudio-line {
    display: block;
  }
  .track-detail-form .uploadaudio-line .ant-upload-picture-card-wrapper {
    margin-top: 14px;
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .tracks-box {
    padding-left: 35px;
    padding-right: 35px;
  }
  .row-track-input.cross {
    position: relative;
    right: -20px;
    height: 24px;
    padding: 0;
    margin: 0;
    top: -20px;
  }
  .row-track-input.left,
  .row-track-input.right {
    display: flex;
  }
  .row-track-input.left .relative-input,
  .row-track-input.right .relative-input {
    width: 100%;
  }
  .row-track-input.name {
    display: none;
  }
  .row-track-input.roles {
    display: none;
  }
  .row-preview-track.name-icon,
  .row-preview-track.role-icon {
    display: initial;
  }
  .track-media-uploader{
    text-align: center;
    margin: 0;
  }
  .track-media-uploader .ant-upload.ant-upload-select-picture-card{
    width: 80%;
    margin: 0;
  }
  .track-detail-form .isrc-setter, .general-info-tracks .creactive-info-title{
    padding-top: 25px;
  }
}
@media (max-width: 790px) {
  .copy-artist-btn {
    width: 100%;
    white-space: pre-wrap;
    min-width: 173px;
  }
}
