.load{
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  display: flex;
  z-index: 1000;
  position: fixed;
  font-size: 15px;
  font-weight: 700;
  padding-top: 85px;
  text-align: center;
  background: #fff;
  border-radius: 11px;
  flex-direction: column;
  justify-content: center;
  color: var(--outrageouse-orange);
}
.spinner{
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.spinner .ant-spin-dot-item{
  background-color: var(--outrageouse-orange);
}