.list-cd.inline-contend{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  text-align: center;
  justify-items: center;
  padding-left: 14px;
  padding-right: 11px 
}
.list-rel.list-artist .new-artist-add{
  margin-right: 0px;
  min-height: 170px;
}
.display-avtr{
  width: 151px !important;
  height: 151px !important;
}
/********* Medias *********/
@media (max-width: 550px){
  ul.list-cd.inline-contend{
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  }
}
@media (max-width: 502px) and (min-width: 490px){
  ul.list-cd.inline-contend{
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }
}
@media (max-width: 451px) and (min-width: 420px){
  ul.list-cd.inline-contend{
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }
}
@media (max-width: 419px) and (min-width: 410px){
  ul.list-cd.inline-contend{
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 409px) and (min-width: 335px){
  ul.list-cd.inline-contend{
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
  .list-cd .cd-data {
    margin-right: 0px;
  }
}
@media (max-width: 334px) and (min-width: 271px){
  ul.list-cd.inline-contend{
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }
}
@media (max-width: 250px) {
  ul.list-cd.inline-contend {
    grid-template-columns: repeat(auto-fill, minmax(9rem, 0fr));
  }
}
@media (max-width: 270px){
  .display-avtr{
    width: 75px !important;
    height: 75px !important;
  }
}