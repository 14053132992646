.general_information {
  text-align: left;
  padding-left: 70px;
  padding-right: 70px;
}

.general_information h2 {
  padding-top: 20px;
}

.general_information .label {
  opacity: 0.6;
}

.general_information .input-general,
.general_information .selector-general .ant-select-selector,
.general_information .ant-picker {
  margin-bottom: unset !important;
}

.general_information .ant-row-space-around {
  justify-content: space-between;
}

.general_information .ant-card {
  border-radius: 8px;
  min-height: 360px; /*For same height in cards*/
}

.general_information .ant-select-disabled,
.general_information .ant-select,
.general_information .ant-picker,
.general_information .ant-picker-disabled {
  width: 100%;
}

.general_information .ant-btn-primary {
  border-radius: 48px;
  background: rgba(255, 95, 46, 0.95);
  border: none;
  font-weight: 600;
}

.general_information .ant-btn-primary[disabled] {
  background: #fafafa;
  border: none;
}
/**************** Medias ****************/
@media (min-width: 992px) {
  .general_information .ant-col-lg-11 {
    min-width: 49%;
  }
}
@media (max-width: 991px) {
  .general_information .info-one {
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .general_information {
    padding-left: 35px;
    padding-right: 35px;
  }
}
