.upc-title{
    font-size: 35px;
    font-weight: 700;
    text-align: left;
    color: #343434;
}
.tabs-top.tab-codes .ant-tabs-tab-btn{
    color: var(--color-black);
    font-size: 20px;
    width: 70px;
    text-align: center;
}
.tabs-top.tab-codes .ant-tabs-tab-disabled{
    cursor: auto;
}
.tabs-top.tab-codes .ant-tabs-tab-disabled .ant-tabs-tab-btn{
    width: auto;
    color: rgba(155, 155, 155, 0.9);
    font-size: 24px;
    line-height: initial;
}
/********* Table *********/
.upcs{
    background: #fff;
    border-radius: 20px;
    max-width: 100%;
    margin-bottom: 35px;
}
.upcs .ant-table-wrapper{
    overflow-x: auto;
    max-height: 390px;
    overflow-y: auto;
}
.upcs .ant-table-container table>thead>tr:first-child th:last-child{
    border-top-right-radius: 20px;
}
.upcs .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 20px;
}
.upcs .ant-table{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.upcs .ant-table-tbody > tr > td:first-child {
  border-left: 3px solid transparent;
}
.upcs .ant-table-tbody>tr>td:first-child.ant-table-cell-row-hover{
  border-left: 3px solid var(--outrageouse-orange);
}
.upcs .ant-table-tbody>tr.ant-table-row:hover>td, .upcs .ant-table-tbody>tr>td.ant-table-cell-row-hover{
  background-color: #ededed;
}
.upcs .table-tracks table thead th{
  color: #3d3d3d;
  text-align: center;
  background: #fff;
  text-transform: uppercase;
  font-size: 14px;
  position: sticky;
  top: 0;
  font-weight: 900;
  z-index: 1;
  padding: 16px;
  overflow-wrap: break-word;
}
.title-codes h1{
    padding-top: 0px;
    color: var(--subtext);
    font-size: 15px;
}
.upcs .pad-code{
  padding-left: 30px;
  width: auto;
}
/********* Buttons *********/
.add-track.upcadd{
    height: 45px;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    margin: 2px 0px 15px;
    border-radius: 5px;
    justify-content: center;
    background-color: var(--outrageouse-orange);
    align-items: center;
    width: max-content;
    padding: 0 20px;
    line-height: normal;
    color: #fff;
    font-weight: 700;
}
.available{
    width: 200px;
    margin: auto 0;
    color: #55546B;
    text-align: left;
}
.save-upc{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 32px 15px 32px;
}
.btns-upc{
    width: 310px;
}
/********* Modal Upc *********/
.add-upc .ant-modal-body{
    padding-top: 0px;
}
.add-upc .text-area-label{
    color: var(--subtext);
    font-size: 12px;
    padding-bottom: 15px;
}
.add-upc .ant-modal-content{
    border-radius: 20px;
}
.add-upc .ant-modal-header{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.add-upc .text-error {
    top: 10px;
}
.add-upc .text-area-general{
    min-height: 180px;
    max-height: 180px;
    height: 180px;
    border: none;
    background: #f4f4f4;
}