.logged{
  padding: 0 20px;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
}
.logged-body{
  font-size: 19px;
  margin-bottom: 25px;
}
.logged-body p{
  font-size: 24px;
  font-weight: 800;
  color: var(--outrageouse-orange);
}
.logout-img{
  width: 100%;

}
@media (max-width: 768px) {
  .logged{
    height: 100%;
  }
  .logout-img{
    height: 300px;
  }
}
@media (max-width: 400px) {
  .logout-img{
    height: 200px;
  }
}