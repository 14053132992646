.container-settings{
	display: flex;
}
/********* Side Menu *********/
.settings{
	width: 193px;
	min-width: 193px;
	color: rgba(69, 69, 69, 0.9);
	border-radius: 20px;
	padding: 9px 11px;
	min-height: 510px;
	background-color: #FFF;
	box-shadow: var(--shadow-elevation-high) !important;
  border: 1px solid #eeeeee;
}
.settings li.ant-menu-item.item-list{
	padding: 0 16px !important;
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
}
.ant-menu-item.user-item{
	padding-left: 0 !important;
  padding-top: 10px;
  pointer-events: none;
}
.user-item .top-info{
	display: flex;
}
.user-item .top-info .name{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 124px;
}
.ant-menu-item.divisor{
  padding: 0 !important;
  height: auto;
}
.ant-menu-item.user-item sub{
	color: #45454599;
  font-size: 11px;
  font-weight: 400;
  display: block;
  padding-top: 10px;
}
.ant-menu-item.user-item .ant-menu-title-content{
	display: flex;
}
.user-item .avatar-default{
	font-size: 29px;
  border: 1px solid #454545e6;
  padding: 2px;
  height: 35px;
  margin-top: 5px;
}
.avatar-stt{
	width:40px;
	height: 40px;
  position: relative;
  top: 5px;
}
.settings .ant-divider-horizontal{
	margin: 10px 0;
}
.settings.ant-menu-submenu-selected{
	color: rgba(69, 69, 69, 0.9);
}
.settings .ant-menu-item.item-list svg{
	font-size: 20px;
	min-width: 20px;
	margin-right: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.item-list a{
	color: rgba(69, 69, 69, 1);
	opacity: 1 !important;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
}
.item-list.ant-menu-item::after{
	border: none;
}
.item-list.ant-menu-item-selected{
	color: #fff;
	border-radius: 8px;
	background: linear-gradient(to right, rgba(166, 192, 254,0.75) 0%, rgba(246, 128, 132, 0.75) 100%) !important;
	box-shadow: 0px 8px 8px -6px rgba(166, 192, 254, 0.58), 0px 8px 16px -6px rgba(246, 128, 132, 0.54);
	transition: background 0.2s ease;
	opacity: 1 !important;
}
.settings .item-list.ant-menu-item{
	box-shadow: unset;
	border-radius: 8px;
  transition: background-color 0.3s linear;
}
.settings .item-back{
	background: linear-gradient(to right, rgba(255,255,255,0.75) 0%, rgba(255,255,255, 0.75) 100%);
  position: relative;
  opacity:1;
  transition:width 20s, opacity 0.6s;
}
.settings .item-list a:hover, .item-list.ant-menu-item-selected .ant-menu-title-content a{
	color: #fff;
}
.settings .item-list.ant-menu-item:hover {
  background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.75));
  position: relative;
  opacity:1;
  transition:width 0.2s, opacity 0.2s;
  transition-delay: width 2s;
  animation-name: gradient-fade;
  animation-duration: 0.2s;   
  -webkit-animation-name: gradient-fade; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 0.2s; /* Chrome, Safari, Opera */
}
.item-list .ant-menu-item-icon:hover{
	color: #fff;
	transition:color 0.2s;
}
@-webkit-keyframes gradient-fade {
    0%   {background:linear-gradient(to right, rgba(166, 192, 254,0), rgba(246, 128, 132,0));}
    2%  {background:linear-gradient(to right, rgba(166, 192, 254,0.01875), rgba(246, 128, 132,0));}
    4%  {background:linear-gradient(to right, rgba(166, 192, 254,0.0375), rgba(246, 128, 132,0.0));}
    6%  {background:linear-gradient(to right, rgba(166, 192, 254,0.05625), rgba(246, 128, 132,0.0));}
    8% {background:linear-gradient(to right, rgba(166, 192, 254,0.075), rgba(246, 128, 132,0));}
    10%  {background:linear-gradient(to right, rgba(166, 192, 254,0.09375), rgba(246, 128, 132,0));}
    12%   {background:linear-gradient(to right, rgba(166, 192, 254,0.1125), rgba(246, 128, 132,0));}
    14%  {background:linear-gradient(to right, rgba(166, 192, 254,0.13125), rgba(246, 128, 132,0));}
    16%  {background:linear-gradient(to right, rgba(166, 192, 254,0.15), rgba(246, 128, 132,0));}
    18%  {background:linear-gradient(to right, rgba(166, 192, 254,0.16875), rgba(246, 128, 132,0));}
    20% {background:linear-gradient(to right, rgba(166, 192, 254,0.1875), rgba(246, 128, 132,0));}
    22%  {background:linear-gradient(to right, rgba(166, 192, 254,0.20625), rgba(246, 128, 132,0.01875));}
    24%   {background:linear-gradient(to right, rgba(166, 192, 254,0.225), rgba(246, 128, 132,0.0375));}
    26%  {background:linear-gradient(to right, rgba(166, 192, 254,0.24375), rgba(246, 128, 132,0.05625));}
    28%  {background:linear-gradient(to right, rgba(166, 192, 254,0.2625), rgba(246, 128, 132,0.075));}
    30%  {background:linear-gradient(to right, rgba(166, 192, 254,0.28125), rgba(246, 128, 132,0.09375));}
    32% {background:linear-gradient(to right, rgba(166, 192, 254,0.3), rgba(246, 128, 132,0.1125));}
    34%  {background:linear-gradient(to right, rgba(166, 192, 254,0.31875), rgba(246, 128, 132,0.13125));}
    36%   {background:linear-gradient(to right, rgba(166, 192, 254,0.3375), rgba(246, 128, 132,0.15));}
    38%  {background:linear-gradient(to right, rgba(166, 192, 254,0.35625), rgba(246, 128, 132,0.16875));}
    40%  {background:linear-gradient(to right, rgba(166, 192, 254,0.375), rgba(246, 128, 132,0.1875));}
    42%  {background:linear-gradient(to right, rgba(166, 192, 254,0.39375), rgba(246, 128, 132,0.20625));}
    44% {background:linear-gradient(to right, rgba(166, 192, 254,0.4125), rgba(246, 128, 132,0.225));}
    46%  {background:linear-gradient(to right, rgba(166, 192, 254,0.43125),rgba(246, 128, 132,0.24375));}
    48%   {background:linear-gradient(to right, rgba(166, 192, 254,0.45), rgba(246, 128, 132,0.2625));}
    50%  {background:linear-gradient(to right, rgba(166, 192, 254,0.46875), rgba(246, 128, 132,0.28125));}
    52%  {background:linear-gradient(to right, rgba(166, 192, 254,0.4875), rgba(246, 128, 132,0.3));}
    54%   {background:linear-gradient(to right, rgba(166, 192, 254,0.50625), rgba(246, 128, 132,0.31875));}
    56%  {background:linear-gradient(to right, rgba(166, 192, 254,0.525), rgba(246, 128, 132,0.3375));}
    58%  {background:linear-gradient(to right, rgba(166, 192, 254,0.54375), rgba(246, 128, 132,0.35625));}
    60%  {background:linear-gradient(to right, rgba(166, 192, 254,0.5625), rgba(246, 128, 132,0.375));}
    62% {background:linear-gradient(to right, rgba(166, 192, 254,0.58125), rgba(246, 128, 132,0.39375));}
    64%  {background:linear-gradient(to right,rgba(166, 192, 254,0.6), rgba(246, 128, 132,0.4125));}
    66%   {background:linear-gradient(to right, rgba(166, 192, 254,0.61875), rgba(246, 128, 132,0.43125));}
    68%  {background:linear-gradient(to right, rgba(166, 192, 254,0.6375), rgba(246, 128, 132,0.45));}
    70%  {background:linear-gradient(to right, rgba(166, 192, 254,0.65625), rgba(246, 128, 132,0.46875));}
    72%  {background:linear-gradient(to right, rgba(166, 192, 254,0.675), rgba(246, 128, 132,0.4875));}
    74% {background:linear-gradient(to right, rgba(166, 192, 254,0.69375), rgba(246, 128, 132,0.50625));}
    76%  {background:linear-gradient(to right, rgba(166, 192, 254,0.7125), rgba(246, 128, 132,0.525));}
    78%   {background:linear-gradient(to right, rgba(166, 192, 254,0.73125),rgba(246, 128, 132,0.54375));}
    80%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.5625));}
    82%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.58125));}
    84%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75),rgba(246, 128, 132,0.6));}
    86% {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.61875));}
    88%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.6375));}
    90%   {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.65625));}
    92%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.675));}
    94%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75),rgba(246, 128, 132,0.69375));}
    96%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.7125));}
    98% {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.73125),);}
    100%  {background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.75));}
}

.item-list svg path{
	stroke: rgba(69, 69, 69, 0.9);
}
.item-list:hover svg path{
	stroke: #fff;
	color: #fff;
}
.item-list.ant-menu-item-selected svg path{
	stroke: #fff !important;
}
.item-list .circle-alert svg path{
  fill: var(--outrageouse-orange);
}
/********* Profile *********/
.content-set{
	width: 100%;
  max-width: 100%;
  color: #454545e6;
	text-align: left;
  min-height: 510px;
  margin-left: 20px;
	padding: 27px 25px;
  border-radius: 20px;
  position: relative;
  background-color: #FFF;
  box-shadow:var(--shadow-elevation-high);
  border: 1px solid #eeeeee;
}
.content-set h2{
	font-weight: 800;
}
.content-set .forms{
	padding: 0;
}
.content-set .forms .gutter-row .col1 {
  padding-right: 20px;
}
.content-set .forms .gutter-row .col2 {
  padding-left: 20px;
}
.content-set .align-right{
	position: absolute;
	bottom: 25px;
	right: 25px;
}
.content-set .align-right button{
	min-width: 150px;
}
.content-set .reg-btn{
	text-align: right;
}
.content-set .input-general, .content-set .selector-general .ant-select-selector, .content-set .ant-input{
	background-color: #F8F8F8;
}
.avatar-profile{
	max-height: 100%;
    width: auto;
    max-width: 113px;
}
/********* Password *********/
.error-pwd{
    color: #FD6A88;
    font-size: 12px;
    font-weight: bold;
}
/********* Payment *********/
.pay .data{
	font-size: 15px;
	padding-bottom: 10px;
}
.pay .card{
	color: #929292;
	font-size: 15px;
	display: inline-block;
}
.pay .sec-plan, .pay .sec-plan ~ .card{
  padding-left: 12px;
}
.pay .data.bg{
  font-size: 16px;
}
.pay .data .update{
  padding: 0 6px;
  min-height: 21px;
  max-height: 23px;
  font-weight: 100;
  margin-left: 10px;
  border-radius: 8px;
  text-transform: capitalize;
}
.pay .data .update:hover, .pay .data .update:focus, .pay .data .update:active{
	filter: brightness(0.9);
}
.pay .contact{
  color: var(--outrageouse-orange);
  display: inline-block;
  padding-left: 9px;
  cursor: pointer;
  text-transform: capitalize;
}
.pay .contact:hover{
	filter: brightness(1.2);
}
.pay .card ul{
	padding-top: 10px;
	padding-left: 12px;
}
.pay .curr-benefits{
  font-size: 15px;
  padding-top: 15px;
}
.pay .curr-benefits li{
  list-style: circle;
  padding-left: 10px;
  padding-top: 2px;
}

.payee-icon{
  width: 16px;
  height: 20px;
}
.payee-icon g{
  fill: rgba(69, 69, 69, 0.9);
}
/********* Payee *********/
.item-list:hover .payee-icon g{
  fill: #fff;
}
.item-list.ant-menu-item-selected .payee-icon g{
  fill: #fff !important;
}
.ant-divider-horizontal.ant-divider-with-text.divider-payee{
  font-size: 13px;
}
.divider-payee span{
  color: #A5A5A5;
}
.text-alert{
  color: var(--outrageouse-orange);
  font-weight: 600;
  font-size: 12px;
}
.top-alert{
  top: 7px;
}
/********* Medias *********/
@media (max-width: 828px){
  .container-settings {
	  display: block;
	}
	.content-set{
		margin-left: 0px;
		min-width: unset;
		width: auto;
	}
	.settings{
    min-height: auto;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
	}
	.settings li.ant-menu-item.item-list{
    display: inline-flex;
    width: 33.33%;
    margin: 0;
    padding: 0 8px !important;
    justify-content: center;
    height: 40px;
    min-width: 90px;
	}
	.settings .ant-menu-item.item-list svg{
		margin-right: 6px;
    font-size: 18px;
    min-width: 18px;
	}
	.settings .ant-menu-item.item-list .ant-menu-title-content{
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
	}
	.item-list a {
		justify-content: center;
	}
}
@media (max-width: 767px){
	.content-set .forms .gutter-row .col1{
    padding-right: 0px;
  }
  .content-set .forms .gutter-row .col2{
    padding-left: 0px;
  }
}

.ant-menu-inline .ant-menu-item {
  padding: 0 16px !important;
  display: flex;
  align-items: center;
}

.ant-menu-inline .ant-menu-item a {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Para asegurar que el icono se mantenga alineado */
.ant-menu-inline .ant-menu-item .anticon {
  min-width: 16px;
  margin-right: 8px;
}

/* Ajuste para el hover state */
.ant-menu-inline .ant-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

/* Ajuste para el estado activo */
.ant-menu-inline .ant-menu-item-selected {
  background-color: #e6f7ff;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 576px) {
  .settings li.ant-menu-item.item-list {
    width: 100%;
    margin: 4px 0;
    padding: 0 12px !important;
    justify-content: flex-start;
  }

  .settings {
    padding: 8px;
  }

  .settings .ant-menu-item.item-list svg {
    margin-right: 10px;
    font-size: 20px;
    min-width: 20px;
    justify-content: center;
  }

  .settings .ant-menu-item.item-list .ant-menu-title-content {
    font-size: 14px;
    text-align: left;
  }

  .item-list a {
    justify-content: flex-start;
    gap: 10px;
  }

  /* Ajuste para el contenedor principal */
  .container-settings {
    flex-direction: column;
  }

  /* Asegurar que el texto se mantenga a la derecha del icono */
  .settings .ant-menu-item.item-list .ant-menu-title-content a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }
}

/* Ajuste para pantallas muy pequeñas */
@media (max-width: 550px) {
  .settings .item-list a:hover, .settings .item-list.ant-menu-item:hover {
    background: none;
    box-shadow: none;
    color: rgba(69, 69, 69, 0.9);
  }
  .settings .item-list a:active, .settings .item-list.ant-menu-item:active, .settings .item-list a:focus {
    color: rgba(69, 69, 69, 0.9);
  } 
  .item-list:hover svg path{
    stroke: rgba(69, 69, 69, 0.9);
  }
  .item-list:hover .payee-icon g{
    fill: rgba(69, 69, 69, 0.9);
  }
  .item-list.ant-menu-item-selected .ant-menu-title-content a{
    color: #fff;
  }
}
@media (max-width: 360px) {
  .settings li.ant-menu-item.item-list {
    padding: 0 8px !important;
  }

  .settings .ant-menu-item.item-list .ant-menu-title-content {
    font-size: 13px;
  }

  .settings .ant-menu-item.item-list svg {
    margin-right: 8px;
    font-size: 18px;
    min-width: 18px;
  }
}

.payee-link {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
}

.payee-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.circle-alert {
  color: var(--outrageouse-orange);
  margin-left: 8px;
  flex-shrink: 0;
  font-size: 16px;
}

/* Mantener los estilos del hover y selección */
.settings .item-list.ant-menu-item-selected{
	color: #fff;
	border-radius: 8px;
	background: linear-gradient(to right, rgba(166, 192, 254,0.75) 0%, rgba(246, 128, 132, 0.75) 100%) !important;
	box-shadow: 0px 8px 8px -6px rgba(166, 192, 254, 0.58), 0px 8px 16px -6px rgba(246, 128, 132, 0.54);
	transition: background 0.2s ease;
	opacity: 1 !important;
}

.settings .item-list.ant-menu-item:hover {
  background:linear-gradient(to right, rgba(166, 192, 254,0.75), rgba(246, 128, 132,0.75));
  position: relative;
  opacity:1;
  transition:width 0.2s, opacity 0.2s;
  transition-delay: width 2s;
  animation-name: gradient-fade;
  animation-duration: 0.2s;   
  -webkit-animation-name: gradient-fade;
  -webkit-animation-duration: 0.2s;
}

/* Asegurar que el ícono de alerta mantenga su color */
.item-list.ant-menu-item-selected .circle-alert,
.item-list:hover .circle-alert {
  color: var(--outrageouse-orange) !important;
}

/* Mantener los estilos responsivos */
@media (max-width: 828px) {
  .settings li.ant-menu-item.item-list {
    display: inline-flex;
    width: 33.33%;
    margin: 0;
    padding: 0 8px !important;
    justify-content: center;
    height: 40px;
    min-width: 90px;
  }
  
  .payee-link {
    justify-content: center !important;
  }
  
  .circle-alert {
    margin-left: 4px;
  }
}
