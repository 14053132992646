.title-catalog{
    display: flex;
    justify-content: space-between;
}
.title-catalog h1, h1.section-title{
    font-size: 39px;
    font-weight: 700;
    text-align: left;
    color: #343434;
}
.title-catalog .filters{
    display: flex;
}
.title-catalog .filters.releases-sct{
    justify-content: space-between;
    width: 100%;
}
.error-release-admin {
    text-align: center;
    padding: 10px 2px;
    font-size: 13px;
    font-weight: 800;
    color: #ff7272;
}
.center-details{
  text-align: center;
  padding: 0 10px;
}
/********* Artists *********/
.identifier{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 11px;
}
.identifier h3{
    color: #808080;
    font-size: 14px;
}
.identifier button {
    margin: 0 5px;
    width: auto;
    height: 30px;
    border: none;
    padding: 0 2px;
    cursor: pointer;
    min-width: 75px;
    font-size: 14px;
    font-weight: 500;
    background: none;
    border-radius: 5px;
    text-transform: capitalize;
    color: #6e6e6e;
    min-height: unset;
}
.dsp-desc{
    font-size: 13px;
    padding: 10px 0;
    color: #9b9b9b;
}
.popover-dsp{
    max-width: 370px;
    width: 100%;
}
b.orng{
    color:var(--outrageouse-orange);
}
.identifier button:hover, .identifier button:active, .identifier button:focus{
    box-shadow: 0px 0px 3px 1px rgb(115, 199, 255, 0.38);
    border: none;
    background: none;
    color: #6e6e6e;
    opacity: 1;
    border-radius: 3px;
    filter: brightness(1);
}
.table-preview-tracks.dsps{
    padding-bottom: 30px;
}
.table-preview-tracks.dsps .ant-table-thead>tr>th{
    padding: 8px;
}
.table-preview-tracks.dsps .ant-table-container{
    max-height: 230px;
}
.dsps .editIcons{
    display: flex;
    justify-content: space-evenly;
}
.dsps .editIcons svg{
    width: 21px;
    height: 21px;
    cursor: pointer;
}
.dsps .editIcons svg path{
    stroke: #a4a4a4;
    fill: #a4a4a4;
}
.subtitle-opt{
    font-size: 14px;
    color: #0000004f;
    padding-bottom: 6px;
    padding-top: 7px;
    width: 100%;
}
.head-artist{
  color: #ff5f2ec4;
}
.ant-pagination-options .ant-select-selector{
  border-radius: 8px !important;
}
.ant-pagination-options .ant-select-selector .ant-select-selection-item{
  padding-top: 0 !important;
}