.container-box.sign{
	padding: 20px 15px 30px 15px;
    text-align: center;
    padding-top: 60px;
    background-color: #fff;
}
.container-box .logo{
	height: auto;
    max-width: 300px;
    width: 100%;
}
.container-box.wizard{
    padding: 0 50px;
}
.container-box .wizard-box{
	background-color: #fff;
    border-radius: 10px;
    padding: 25px 130px;
    max-width: 1300px;
    margin: auto;
    text-align: left;
    position: relative;
    margin-top: 30px;
    box-shadow: var(--shadow-elevation-high);
}
.wizard-box h1{ 
    font-size: 38px;
    margin-bottom: 0px;
}
.wizard-box h2{ 
  font-size: 30px;
  margin-bottom: 0px;
}
.wizard-box h3{
    font-size: 17px;
}
.forms{
    padding: 25px 0 15px 0;
}
.forms .gutter-row .col1{
    padding-right: 30px;
}
.forms .gutter-row .col2{
    padding-left: 30px;
}
.forms .gutter-row .col-n{
    padding-right: 15px;
}
.forms .gutter-row .col-r{
    padding-left: 15px;
}
.forms label{
    padding: 0px 0px 5px 0px;
    color:  #808080;
}
.radio-options{
    display: block;
    min-height: 40px;
    padding: 8px 0px;
}
.back-btn{
    background-color: transparent;
    color: var(--subtext);
    text-transform: capitalize;
    font-weight:  400;
}
.forms .ant-input-affix-wrapper-disabled{
    background: #fcfcfc;
}
.pay-msg{
  font-size: 17px;
  padding-bottom: 20px;
}
/********Code*******/
.forms .centered-input{
    margin: 0 auto;
    text-align: center;
    background-color: #fff !important;
}
a.plain-link{
    color: #ff5f2e
}
p.sub-message{
    margin-top: 20px;
    margin-bottom: 0px;
}
.form-verify{
    padding-top: 15px;
}
.form-verify .text-err {
    top: 8px;
    color: #FD6A88;
    font-size: 11px;
    font-weight: bold;
    position: relative;
}
/********* Pricing *********/
.pricing-options{
    display: flex;
    justify-content: space-between;
}
.pricing-box{
    width: 100%;
    padding: 20px;
    border-radius: 25px;
    position: relative;
    min-height: 460px;
    margin-bottom: 15px;
    padding-bottom: 50px;
}
.pricing-box .price-subtitle{
    font-weight: bold;
    font-size: 17px;
}
.pricing-box ul{
    list-style-type: none;
    padding-left: 0;
}
.pricing-box ul li{
    padding-bottom: 7px;
}
.pricing-box .price{
    font-size: 32px;
    font-weight: bold;
}
.pricing-box .price .period{
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
}
.pricing-box.core{
    background-color: #f8f8f8;
    margin-right: 30px;
}
.pricing-box.plus{
    color: #fff ;
    margin-left: 30px;
    background-color: #1C1C1C;
}
.plus h1, .plus h2, .plus h3{
    color:  #fff;
}
.price-btn{
    position: absolute;
    right: 0;
    width: 90%;
    left: 0;
    margin: auto;
    bottom: 20px;
}
.price-btn:hover,  .general-btn:focus{
  opacity: 1;
  filter: brightness(1.25) ;
}
.check-benefit{
    margin-right: 8px;
}
.selectedPlan{
    box-shadow: 0px 0px 15px 2px var(--malibu);
}
.confirm-btn{
  margin-bottom: 50px;
  height: 50px;
  border-radius: 12px;
  box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%), 0px 8px 16px -6px rgb(24 39 75 / 8%);
  margin-top: 30px;
}
/********* Terms *********/
.terms h3{
    padding-top: 25px;
}
.terms-box{
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 5px;
    padding: 15px;
    text-align: justify;
    height: 370px;
    overflow-y: auto;
}
.check-terms{
    padding: 15px 0;
}
/********* Medias *********/
@media (min-width: 768px) and (max-width: 1095px){
    .container-box .wizard-box{
        padding: 25px 90px;
    }
}
@media (max-width: 767px){
    .container-box .wizard-box{
        padding: 25px 70px;
    }
    .forms .gutter-row .col1, .forms .gutter-row .col-n{
        padding-right: 0px;
    }
    .forms .gutter-row .col2, .forms .gutter-row .col-r{
        padding-left: 0px;
    }
    .reg-btn{
        display: flex;
    }
}
@media (max-width: 490px){
    .container-box .wizard-box{
        padding: 25px 30px;
        margin-bottom: 110px;
    }
    .wizard-box h1{
        font-size: 29px;
    }
    .wizard-box h3 {
        font-size: 15px;
    }
}

@media (max-width: 1023px) and (min-width: 960px){
    .pricing-box.core{
        margin-right: 15px;
        min-height: 477px;
    }
    .pricing-box.plus{
        margin-left: 15px;
        min-height: 477px;
    }
}
@media (max-width: 960px){
    .pricing-options{
        display: block;
    }
    .pricing-box.core{
        margin-right: 0px;
        margin-bottom: 30px;
        min-height: unset;
    }
    .pricing-box.plus{
        margin-left: 0px;
        min-height: unset;
    }
    .price-btn{
        position: unset;
        width: 100%;
    }
}

.has-notification {
  opacity: 0.97;
  transition: opacity 0.3s ease;
}


.has-notification::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999;
}

.beta-notification {
  z-index: 1001 !important;
  position: relative;
}

.beta-notification a {
  color: var(--outrageouse-orange);
  text-decoration: underline;
}

.beta-notification a:hover {
  color: #40a9ff;
}
