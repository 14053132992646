.reload{
  padding: 0 35px;
}
.reload .title{
  font-size: 95px;
  font-weight: 900;
  padding-top: 30px;
  margin-bottom: 17px;
}
.reload p{
  font-size: 20px;
}
.reload p b{
  color: #E66070;
  font-weight: 800;
  cursor: pointer;
  padding-left: 5px;
}
.reload p b:hover{
  filter: brightness(1.2);
}