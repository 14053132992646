.rights-box .title.delivery{
    padding-bottom: 20px;
}
.rights-container.delivery .right-column{
    min-width: 250px;
    padding-right: 40px;
}
.deliv-desc{
    text-align: left;
    color: #A5A5A5;
    font-size: 14px;
    padding-bottom: 25px;
}
.date-delivery{
    margin-bottom: 20px;
    margin-bottom: 20px;
    width: 100%;
    border: none;
    border-radius: 10px;
    height: 40px;
}
.cards-delivery{
    display: flex;
    justify-content: space-between;
}
.cards-delivery .col-deliv{
    max-width: 49%;
}
.cards-delivery .col-deliv .ant-card{
  border-radius: 8px;
}
.delivery .asset-level-date{
    font-size: 15px;
    font-weight: 600;
}
.delivery .orng{
    color: var(--outrageouse-orange);
}
.date-delivery.ant-picker-disabled{
    background: #fdfdfd;
}
.date-delivery.ant-picker-disabled input, .date-delivery.ant-picker-disabled ~ .suffix-text{
    color: #919191cc !important;
}
.date-delivery.ant-picker-disabled .ant-picker-suffix .orng{
    color: #ff5f2e75;
}
.selector-general.tier .ant-select-selector{
    background-color: #fff !important;
    border-radius: 10px !important;
}
.selector-general.tier .ant-select-selection-placeholder{
    padding: 5px 0;
}
.selector-general.tier.ant-select-disabled .ant-select-selector{
    background-color: #fdfdfd !important;
    color: #919191cc !important;
}
/************ medias ************/
@media (max-width: 1044px) {
  .cards-delivery{
    display: block;
  }
  .cards-delivery .col-deliv{
    max-width: 100%;
  }
  .rights-container .right-column sub{
    min-width: unset !important;
    line-height: 1;
  }
  .rights-container.delivery .right-column{
    min-width: auto;
    width: 100%;
  }
}
@media (max-width: 550px) {
.rights-container.delivery .right-column{
    padding-right: 0;
    min-width: 100%;
  }
}
