.footer {
	background-color: #ffffff;
	padding: 20px;
	width: 100%;
	border-top: 1px solid #e8e8e8;
	margin-top: auto; /* Push footer to bottom */
	position: relative; /* Added position relative */
	bottom: 0;
	left: 0;
	right: 0;
	flex-shrink: 0; /* Prevent footer from shrinking */
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-content {
	max-width: 1200px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 15px; /* Added padding for mobile */
}

.footer-links {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
	color: #454545;
	font-size: 14px;
	width: 100%;
	text-align: center;
}

.footer-links a {
	color: #454545;
	text-decoration: none;
	transition: color 0.3s ease;
}

.footer-links a:hover {
	color: var(--malibu);
}

@media (max-width: 550px) {
	.footer {
		padding: 15px 10px;
		position: relative;
		bottom: 0;
	}
	
	.footer-content {
		padding: 0 10px;
	}
	
	.footer-links {
		flex-direction: column;
		gap: 8px;
		text-align: center;
		font-size: 12px;
		width: 100%;
	}
	
	.footer-links span {
		text-align: center;
		word-break: break-word;
		width: 100%;
		padding: 0 10px;
	}
}